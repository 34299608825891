import { Injectable } from '@angular/core';

@Injectable()
export class AppInfoService {
  constructor() { }
  personalInfo: any;

  public set PersonalInfo(personalInfo) {
    this.personalInfo = personalInfo;
  }

  public get getPersonalInfo() {
    return this.personalInfo;
  }

  public get title() {
    return 'SIRE';
  }
  public get menu() {
    return 'Manager';
  }
}
