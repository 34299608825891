import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VesselCrewService } from 'src/app/shared/services/vessel-crew.service';
import { VesselCrews } from 'src/app/shared/Models/VesselCrew';
import { VesselService } from 'src/app/shared/services/vessels.service';
import { User } from 'src/app/shared/Models/User';
import { DxDropDownBoxTypes } from 'devextreme-angular/ui/drop-down-box';
import { CrewService } from 'src/app/shared/services/crewService';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: 'app-vesselcrew-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.css',
})
export class AddComponent implements  OnInit {

  constructor(
    private vesselcrewservice: VesselCrewService,
    private _crewService: CrewService,
    private vesselservice: VesselService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _spinner: SpinnerService,
  ) {
    // this.vesselcrewservice
    //   .getVesselCrew().subscribe((res=>{
    //     this.VesselOptions = res.map((vessel) => vessel.Name);
    //   }));
      this.vesselservice.getVessels().subscribe((res) => {
        this.VesselOptions = res.map((vessel) => ({
          id: vessel.id,
          name: vessel.name,
        }));
      });

  }
  rankOptions: string[] = [
    'Master',
    'Chief Engineer',
    'Chief Officer',
    'Second Officer',
    'Second Engineer',
    'Third Officer',
    'Third Engineer',
    'Fourth Engineer',
    'Fifth Engineer',
    'Deck Cadet',
    'Electrician',
    'Motorman',
    'Chief Cook',
    'Able Body',
    'Pumpman',
    'Wiper',
    'Messman',
    'OS',
    'Bosun',
    'Welder',
    'Fitter'

  ];
  vesselCrewForm: FormGroup;
  VesselOptions: any[];
  crew: User = new User();
  @Input() isShowAddForm: boolean;
  @Output() ShowAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() isCrewAdded: EventEmitter<boolean> =new EventEmitter<boolean>();
  vesselcrew: VesselCrews = new VesselCrews();
  selectedType: string | null = null;

  ngOnInit() {
    this.vesselCrewForm = this.formBuilder.group({
      Name: ['', Validators.required],
    });
    }

  onTypeSelectionChanged(selectedValue:  DxDropDownBoxTypes.OptionChangedEvent) {
    this.selectedType = selectedValue.value;
  }
  onVesselSelectionChanged(selectedValue:  DxDropDownBoxTypes.OptionChangedEvent) {
    this.crew.vesselId = selectedValue.value;
  }
  DataSaved() {
     this._spinner.showLoader();
      this.crew.designation = this.selectedType;
      this._crewService.addCrew(this.crew).subscribe((res) => {
        this.isShowAddForm = false;
        this.isCrewAdded.emit(true);
        this._spinner.hideLoader();
      },
          (error) => {
      // Handle error respons
            this._spinner.hideLoader();
     }) ;


      this.toastr.success('Crew added!');

  }
  closePopUp() {
    this.isShowAddForm = false;
    this.ShowAddFormChange.emit(this.isShowAddForm);
  }
}
