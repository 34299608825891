import { Component, HostBinding, OnInit, ChangeDetectorRef } from "@angular/core";
import { AuthService, ScreenService, AppInfoService } from "./shared/services";
import { Router } from "@angular/router";
import { TabService } from "./shared/services/tab.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @HostBinding("class") get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(" ");
  }
  tabItems: Tab[] = [];

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    public _router: Router,
    private cdr: ChangeDetectorRef,
    private tabService:TabService
  ) {
    this.tabService.tabUpdate$.subscribe(() => {
      this.initializeTabItems();
    });
  }

  ngOnInit(): void {
    this.initializeTabItems();
  }

  initializeTabItems(): void {
    if (localStorage.getItem("roleId") === "3") {
      this.tabItems = [
        { id: 1, icon: "assets/home.png" },
        { id: 9, text: "Vessel Questionnaire" }
      ];
    }
   else if (localStorage.getItem("roleId") === "2") {
      this.tabItems = [
        { id: 1, icon: "assets/home.png" },
        { id: 7, text: "Manage Vessel Questionnaire" },
        { id: 8, text: "Manage Vessel Crew" },
      ];
    } else {
      this.tabItems = [
        { id: 1, icon: "assets/home.png" },
        { id: 2, text: "Manage Vessels" },
        { id: 3, text: "Manage Vessel Crew" },
        { id: 4, text: "Manage Programs" },
        { id: 5, text: "SIRE Calendar" },
        { id: 6, text: "Reports" },
      ];
    }
    this.cdr.detectChanges();
  }

  isAuthorized(): boolean {
    return this.authService.isLoggedIn;
  }

  onItemClick(item: Tab): void {
    switch (item.id) {
      case 1:
        this._router.navigate(["/home"]);
        break;
      case 2:
        this._router.navigate(["/vessel"]);
        break;
      case 3:
        this._router.navigate(['/vessel-crew', item.id]);
        break;
      case 4:
        this._router.navigate(["/program"]);
        break;
      case 5:
        this._router.navigate(["/sire-calendar"]);
        break;
      case 6:
        this._router.navigate(["/report"]);
        break;
      case 7:
        this._router.navigate(["/manage-vessel-questionnaire"]);
        break;
      case 8:
        this._router.navigate(['/vessel-crew', item.id]);
        break;
        case 9:
          this._router.navigate(['/crew-questions']);
          break;
      default:
        this._router.navigate(["/**"]);
        break;
    }
  }
}

export class Tab {
  id: number;
  text?: string;
  icon?: string;
}
