export class User {
  id: number;
  clientCode: string;
  email: string;
  password: string;
  image: any;
  imageData: any;
  isAdmin: boolean;
  emailConfirmed: boolean;
  firstName: string;
  lastName: string;
  license: string;
  licenseExpiresOn: string;
  department: string;
  isBusinessPartner: boolean;
  isIndividualContractor: boolean;
  userType: any;
  jobRole: string;
  role: any;
  roleId:number;
  dateRegistered: string;
  adminClientCode: string;
  noOfUsers: number;
  currentUser: number;
  isActive: boolean;
  dateExpiryDoc: string;
  additionalUsers: number;
  xmlExplorer: string;
  dateExpiryAcc: string;
  userName: string;
  canUseTa: boolean;
  canUseDm: boolean;
  xmlData: string;
  canUsePm: boolean;
  canUseGa: boolean;
  canUseSales: boolean;
  canUseNc: boolean;
  canUseLs: boolean;
  canUseLearn: boolean;
  company: string;
  designation:string;
  vesselId:string;
  vesselName:string;
}
