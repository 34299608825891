import { Component } from "@angular/core";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";
import { AuditPreparationReport } from "src/app/shared/Models/AuditPreparationReport";

@Component({
  selector: "app-audit-preparation",
  templateUrl: "./audit-preparation.component.html",
  styleUrl: "./audit-preparation.component.css",
})
export class AuditPreparationComponent {
  AuditPreparationOptions: AuditPreparationReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.AuditPreparationOptions = reportservice.getAuditPreparationReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
