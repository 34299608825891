import { Component } from "@angular/core";
import { FleetOverviewReport } from "src/app/shared/Models/FleetOverviewReport";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-fleet-overview",
  templateUrl: "./fleet-overview.component.html",
  styleUrl: "./fleet-overview.component.css",
})
export class FleetOverviewComponent {
  FleetOverviewOptions: FleetOverviewReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.FleetOverviewOptions = reportservice.getFleetOverviewReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
