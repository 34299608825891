import { Component, Input } from "@angular/core";

@Component({
  selector: "app-vessel-edit",
  templateUrl: "./edit.component.html",
  styleUrl: "./edit.component.css",
})
export class EditComponent {
  @Input() isShowEditForm: boolean;
  constructor() {}
}
