import { Routes, RouterModule } from '@angular/router';
import { CaptainComponent } from './captain.component';
import { NgModule } from '@angular/core';
import { ManageQuestionComponent } from './manage-question/manage-question.component';

const routes:Routes=[
  {
    path: '',
    component:CaptainComponent,
    children:[
      {
        path:'manage-vessel-questionnaire/:id',
        component:ManageQuestionComponent
      }
    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaptainRoutingModule{
  static components = [
   CaptainComponent,
   ManageQuestionComponent
  ];
};
