import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private storageKey = 'questionId';
  private questionId: BehaviorSubject<string>;
  currentQuestionId: any;

  constructor() {
    const storedQuestionId = localStorage.getItem(this.storageKey);
    this.questionId = new BehaviorSubject<string>(storedQuestionId || 'a');
    this.currentQuestionId = this.questionId.asObservable();
  }

  sendQuestionIdFromSprintQuestion(message: string) {
    localStorage.setItem(this.storageKey, message);
    this.questionId.next(message);
  }
}
