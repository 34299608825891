// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-header {
  text-align: center;
  margin-bottom: 40px;
}
.login-header .title {
  background-image: url('SIRE-logo.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100px;
}

.dx-field {
  margin: 0 0 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/login-form/login-form.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;AADF;AAGE;EACE,sCAAA;EACA,4BAAA;EACA,0BAAA;EACA,aAAA;AADJ;;AAKA;EACE,kBAAA;AAFF","sourcesContent":["@import \"../../../../themes/generated/variables.base.scss\";\n\n.login-header {\n  text-align: center;\n  margin-bottom: 40px;\n\n  .title {\n    background-image: url('../../../../assets/SIRE-logo.jpg');\n    background-repeat: no-repeat;\n    background-size: 100% 100%;\n    height: 100px;\n  }\n}\n\n.dx-field {\n  margin: 0 0 30px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
