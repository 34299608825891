import { Injectable } from "@angular/core";
import { AuditPreparationReport } from "src/app/shared/Models/AuditPreparationReport";
import { CrewPerformanceReport } from "src/app/shared/Models/CrewPerformanceReport";
import { DefectAnalysisReport } from "src/app/shared/Models/DefectAnalysisReport";
import { FleetOverviewReport } from "src/app/shared/Models/FleetOverviewReport";
import { HistoricalAuditReport } from "src/app/shared/Models/HistoricalAuditReport";
import { Reports } from "src/app/shared/Models/Reports";
import { UserActivityLogReport } from "src/app/shared/Models/UserActivityLogReport";

export const report: Reports[] = [
  {
    Id: 1,
    Name: "Fleet Overview",
  },
  {
    Id: 2,
    Name: "Audit Preparation Status",
  },
  {
    Id: 3,
    Name: "Defect Analysis Report",
  },
  {
    Id: 4,
    Name: "Crew Performance Report",
  },
  {
    Id: 5,
    Name: "Historical Audit Data",
  },
  {
    Id: 6,
    Name: "User Activity Log",
  },
];

export const FleetOverview: FleetOverviewReport[] = [
  {
    Id: 1,
    Name: "FleetOverview1",
    VesselId: 1,
    VesselName: "Vessel1",
    TypeId: 1,
    TypeName: "Chemical",
    CurrentStatus: "Active",
    NextInspectionDate: "2024-02-23",
    LastInspectionDate: "2024-02-23",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 2,
    Name: "FleetOverview2",
    VesselId: 2,
    VesselName: "Vessel2",
    TypeId: 2,
    TypeName: "LNG",
    CurrentStatus: "InActive",
    NextInspectionDate: "2024-02-24",
    LastInspectionDate: "2024-02-24",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 3,
    Name: "FleetOverview3",
    VesselId: 3,
    VesselName: "Vessel3",
    TypeId: 1,
    TypeName: "Chemical",
    CurrentStatus: "Active",
    NextInspectionDate: "2024-02-25",
    LastInspectionDate: "2024-02-25",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 4,
    Name: "FleetOverview4",
    VesselId: 3,
    VesselName: "Vessel3",
    TypeId: 2,
    TypeName: "LNG",
    CurrentStatus: "InActive",
    NextInspectionDate: "2024-02-26",
    LastInspectionDate: "2024-02-26",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 5,
    Name: "FleetOverview5",
    VesselId: 4,
    VesselName: "Vessel4",
    TypeId: 1,
    TypeName: "Chemical",
    CurrentStatus: "Active",
    NextInspectionDate: "2024-02-27",
    LastInspectionDate: "2024-02-27",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 6,
    Name: "FleetOverview6",
    VesselId: 5,
    VesselName: "Vessel5",
    TypeId: 2,
    TypeName: "LNG",
    CurrentStatus: "InActive",
    NextInspectionDate: "2024-02-28",
    LastInspectionDate: "2024-02-28",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 7,
    Name: "FleetOverview7",
    VesselId: 7,
    VesselName: "Vessel7",
    TypeId: 1,
    TypeName: "Chemical",
    CurrentStatus: "Active",
    NextInspectionDate: "2024-02-29",
    LastInspectionDate: "2024-02-29",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 8,
    Name: "FleetOverview8",
    VesselId: 8,
    VesselName: "Vessel8",
    TypeId: 2,
    TypeName: "LNG",
    CurrentStatus: "InActive",
    NextInspectionDate: "2024-03-01",
    LastInspectionDate: "2024-03-01",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 9,
    Name: "FleetOverview9",
    VesselId: 9,
    VesselName: "Vessel9",
    TypeId: 1,
    TypeName: "Chemical",
    CurrentStatus: "Active",
    NextInspectionDate: "2024-03-02",
    LastInspectionDate: "2024-03-02",
    ComplianceStatus: "InProcess",
  },
  {
    Id: 10,
    Name: "FleetOverview10",
    VesselId: 10,
    VesselName: "Vessel10",
    TypeId: 2,
    TypeName: "LNG",
    CurrentStatus: "InActive",
    NextInspectionDate: "2024-03-03",
    LastInspectionDate: "2024-03-03",
    ComplianceStatus: "InProcess",
  },
];
export const AuditPreparation: AuditPreparationReport[] = [
  {
    Id: 1,
    Name: "AuditPreparation1",
    VesselId: 1,
    VesselName: "Vessel1",
    PreparationStatus: "Active",
    PlannedAuditDate: "2024-02-23",
    ActualAuditDate: "2024-02-23",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 1,
    AuditType: "A",
  },
  {
    Id: 2,
    Name: "AuditPreparation2",
    VesselId: 2,
    VesselName: "Vessel2",
    PreparationStatus: "InActive",
    PlannedAuditDate: "2024-02-24",
    ActualAuditDate: "2024-02-24",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 2,
    AuditType: "B",
  },
  {
    Id: 3,
    Name: "AuditPreparation3",
    VesselId: 3,
    VesselName: "Vessel3",
    PreparationStatus: "Active",
    PlannedAuditDate: "2024-02-25",
    ActualAuditDate: "2024-02-25",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 1,
    AuditType: "A",
  },
  {
    Id: 4,
    Name: "AuditPreparation4",
    VesselId: 4,
    VesselName: "Vessel4",
    PreparationStatus: "InActive",
    PlannedAuditDate: "2024-02-26",
    ActualAuditDate: "2024-02-26",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 2,
    AuditType: "B",
  },
  {
    Id: 5,
    Name: "AuditPreparation5",
    VesselId: 5,
    VesselName: "Vessel5",
    PreparationStatus: "Active",
    PlannedAuditDate: "2024-02-27",
    ActualAuditDate: "2024-02-27",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 1,
    AuditType: "A",
  },
  {
    Id: 6,
    Name: "AuditPreparation6",
    VesselId: 6,
    VesselName: "Vessel6",
    PreparationStatus: "InActive",
    PlannedAuditDate: "2024-02-28",
    ActualAuditDate: "2024-02-28",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 2,
    AuditType: "B",
  },
  {
    Id: 7,
    Name: "AuditPreparation7",
    VesselId: 7,
    VesselName: "Vessel7",
    PreparationStatus: "Active",
    PlannedAuditDate: "2024-02-29",
    ActualAuditDate: "2024-02-29",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 1,
    AuditType: "A",
  },
  {
    Id: 8,
    Name: "AuditPreparation8",
    VesselId: 8,
    VesselName: "Vessel8",
    PreparationStatus: "InActive",
    PlannedAuditDate: "2024-03-01",
    ActualAuditDate: "2024-03-01",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 2,
    AuditType: "B",
  },
  {
    Id: 9,
    Name: "AuditPreparation9",
    VesselId: 9,
    VesselName: "Vessel9",
    PreparationStatus: "Active",
    PlannedAuditDate: "2024-03-02",
    ActualAuditDate: "2024-03-02",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 1,
    AuditType: "A",
  },
  {
    Id: 10,
    Name: "AuditPreparation10",
    VesselId: 10,
    VesselName: "Vessel10",
    PreparationStatus: "InActive",
    PlannedAuditDate: "2024-03-03",
    ActualAuditDate: "2024-03-03",
    ChecklistCompletionPercentage: "InProcess",
    AuditId: 2,
    AuditType: "B",
  },
];
export const DefectAnalysis: DefectAnalysisReport[] = [
  {
    Id: 1,
    Name: "DefectAnalysis1",
    VesselId: 1,
    VesselName: "Vessel1",
    ResolutionStatus: "Active",
    InspectionDate: "2024-02-23",
    TimetoResolve: 2,
    DefectDescription: "InProcess",
    DefectCategory: "A",
  },
  {
    Id: 2,
    Name: "DefectAnalysis2",
    VesselId: 2,
    VesselName: "Vessel2",
    ResolutionStatus: "InActive",
    InspectionDate: "2024-02-24",
    TimetoResolve: 6,
    DefectDescription: "InProcess",
    DefectCategory: "B",
  },
  {
    Id: 3,
    Name: "DefectAnalysis3",
    VesselId: 3,
    VesselName: "Vessel3",
    ResolutionStatus: "Active",
    InspectionDate: "2024-02-25",
    TimetoResolve: 4,
    DefectDescription: "InProcess",
    DefectCategory: "A",
  },
  {
    Id: 4,
    Name: "DefectAnalysis4",
    VesselId: 4,
    VesselName: "Vessel4",
    ResolutionStatus: "InActive",
    InspectionDate: "2024-02-26",
    TimetoResolve: 9,
    DefectDescription: "InProcess",
    DefectCategory: "B",
  },
  {
    Id: 5,
    Name: "DefectAnalysis5",
    VesselId: 5,
    VesselName: "Vessel5",
    ResolutionStatus: "Active",
    InspectionDate: "2024-02-27",
    TimetoResolve: 3,
    DefectDescription: "InProcess",
    DefectCategory: "A",
  },
  {
    Id: 6,
    Name: "DefectAnalysis6",
    VesselId: 6,
    VesselName: "Vessel6",
    ResolutionStatus: "InActive",
    InspectionDate: "2024-02-28",
    TimetoResolve: 6,
    DefectDescription: "InProcess",
    DefectCategory: "B",
  },
  {
    Id: 7,
    Name: "DefectAnalysis7",
    VesselId: 7,
    VesselName: "Vessel7",
    ResolutionStatus: "Active",
    InspectionDate: "2024-02-29",
    TimetoResolve: 8,
    DefectDescription: "InProcess",
    DefectCategory: "A",
  },
  {
    Id: 8,
    Name: "DefectAnalysis8",
    VesselId: 8,
    VesselName: "Vessel8",
    ResolutionStatus: "InActive",
    InspectionDate: "2024-03-01",
    TimetoResolve: 1,
    DefectDescription: "InProcess",
    DefectCategory: "B",
  },
  {
    Id: 9,
    Name: "DefectAnalysis9",
    VesselId: 9,
    VesselName: "Vessel9",
    ResolutionStatus: "Active",
    InspectionDate: "2024-03-02",
    TimetoResolve: 5,
    DefectDescription: "InProcess",
    DefectCategory: "A",
  },
  {
    Id: 10,
    Name: "DefectAnalysis10",
    VesselId: 10,
    VesselName: "Vessel10",
    ResolutionStatus: "InActive",
    InspectionDate: "2024-03-03",
    TimetoResolve: 7,
    DefectDescription: "InProcess",
    DefectCategory: "B",
  },
];
export const CrewPerformance: CrewPerformanceReport[] = [
  {
    Id: 1,
    Name: "CrewPerformance1",
    VesselId: 1,
    VesselName: "Vessel1",
    CompletionStatus: "Active",
    InspectionDate: "2024-02-23",
    ResponseTime: 2,
    ChecklistItem: "InProcess",
  },
  {
    Id: 2,
    Name: "CrewPerformance2",
    VesselId: 2,
    VesselName: "Vessel2",
    CompletionStatus: "InActive",
    InspectionDate: "2024-02-24",
    ResponseTime: 6,
    ChecklistItem: "InProcess",
  },
  {
    Id: 3,
    Name: "CrewPerformance3",
    VesselId: 3,
    VesselName: "Vessel3",
    CompletionStatus: "Active",
    InspectionDate: "2024-02-25",
    ResponseTime: 4,
    ChecklistItem: "InProcess",
  },
  {
    Id: 4,
    Name: "CrewPerformance4",
    VesselId: 4,
    VesselName: "Vessel4",
    CompletionStatus: "InActive",
    InspectionDate: "2024-02-26",
    ResponseTime: 9,
    ChecklistItem: "InProcess",
  },
  {
    Id: 5,
    Name: "CrewPerformance5",
    VesselId: 5,
    VesselName: "Vessel5",
    CompletionStatus: "Active",
    InspectionDate: "2024-02-27",
    ResponseTime: 3,
    ChecklistItem: "InProcess",
  },
  {
    Id: 6,
    Name: "CrewPerformance6",
    VesselId: 6,
    VesselName: "Vessel6",
    CompletionStatus: "InActive",
    InspectionDate: "2024-02-28",
    ResponseTime: 6,
    ChecklistItem: "InProcess",
  },
  {
    Id: 7,
    Name: "CrewPerformance7",
    VesselId: 7,
    VesselName: "Vessel7",
    CompletionStatus: "Active",
    InspectionDate: "2024-02-29",
    ResponseTime: 8,
    ChecklistItem: "InProcess",
  },
  {
    Id: 8,
    Name: "CrewPerformance8",
    VesselId: 8,
    VesselName: "Vessel8",
    CompletionStatus: "InActive",
    InspectionDate: "2024-03-01",
    ResponseTime: 1,
    ChecklistItem: "InProcess",
  },
  {
    Id: 9,
    Name: "CrewPerformance9",
    VesselId: 9,
    VesselName: "Vessel9",
    CompletionStatus: "Active",
    InspectionDate: "2024-03-02",
    ResponseTime: 5,
    ChecklistItem: "InProcess",
  },
  {
    Id: 10,
    Name: "CrewPerformance10",
    VesselId: 10,
    VesselName: "Vessel10",
    CompletionStatus: "InActive",
    InspectionDate: "2024-03-03",
    ResponseTime: 7,
    ChecklistItem: "InProcess",
  },
];
export const HistoricalAudit: HistoricalAuditReport[] = [
  {
    Id: 1,
    Name: "HistoricalAudit1",
    VesselId: 1,
    VesselName: "Vessel1",
    AuditId: 1,
    AuditType: "A",
    AuditDate: "2024-02-23",
    ComplianceStatus: "Active",
    Findings: "finding1",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 2,
    Name: "HistoricalAudit2",
    VesselId: 2,
    VesselName: "Vessel2",
    AuditId: 2,
    AuditType: "B",
    AuditDate: "2024-02-23",
    ComplianceStatus: "InActive",
    Findings: "finding2",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 3,
    Name: "HistoricalAudit3",
    VesselId: 3,
    VesselName: "Vessel3",
    AuditId: 1,
    AuditType: "A",
    AuditDate: "2024-02-23",
    ComplianceStatus: "Active",
    Findings: "finding3",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 4,
    Name: "HistoricalAudit4",
    VesselId: 4,
    VesselName: "Vessel4",
    AuditId: 2,
    AuditType: "B",
    AuditDate: "2024-02-23",
    ComplianceStatus: "InActive",
    Findings: "finding4",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 5,
    Name: "HistoricalAudit5",
    VesselId: 5,
    VesselName: "Vessel5",
    AuditId: 1,
    AuditType: "A",
    AuditDate: "2024-02-23",
    ComplianceStatus: "Active",
    Findings: "finding5",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 6,
    Name: "HistoricalAudit6",
    VesselId: 6,
    VesselName: "Vessel6",
    AuditId: 2,
    AuditType: "B",
    AuditDate: "2024-02-23",
    ComplianceStatus: "InActive",
    Findings: "finding6",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 7,
    Name: "HistoricalAudit7",
    VesselId: 7,
    VesselName: "Vessel7",
    AuditId: 1,
    AuditType: "A",
    AuditDate: "2024-02-23",
    ComplianceStatus: "Active",
    Findings: "finding7",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 8,
    Name: "HistoricalAudit8",
    VesselId: 8,
    VesselName: "Vessel8",
    AuditId: 2,
    AuditType: "B",
    AuditDate: "2024-02-23",
    ComplianceStatus: "InActive",
    Findings: "finding8",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 9,
    Name: "HistoricalAudit9",
    VesselId: 9,
    VesselName: "Vessel9",
    AuditId: 1,
    AuditType: "A",
    AuditDate: "2024-02-23",
    ComplianceStatus: "Active",
    Findings: "finding9",
    CorrectiveActions: "InProcess",
  },
  {
    Id: 10,
    Name: "HistoricalAudit10",
    VesselId: 10,
    VesselName: "Vessel10",
    AuditId: 2,
    AuditType: "B",
    AuditDate: "2024-02-23",
    ComplianceStatus: "InActive",
    Findings: "finding10",
    CorrectiveActions: "InProcess",
  },
];
export const UserActivityLog: UserActivityLogReport[] = [
  {
    Id: 1,
    Name: "UserActivityLog1",
    VesselId: 1,
    VesselName: "Vessel1",
    ActionId: 1,
    ActionName: "A",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription1",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 2,
    Name: "UserActivityLog2",
    VesselId: 2,
    VesselName: "Vessel2",
    ActionId: 2,
    ActionName: "B",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription2",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 3,
    Name: "UserActivityLog3",
    VesselId: 3,
    VesselName: "Vessel3",
    ActionId: 1,
    ActionName: "A",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription3",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 4,
    Name: "UserActivityLog4",
    VesselId: 4,
    VesselName: "Vessel4",
    ActionId: 2,
    ActionName: "B",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription4",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 5,
    Name: "UserActivityLog5",
    VesselId: 5,
    VesselName: "Vessel5",
    ActionId: 1,
    ActionName: "A",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription5",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 6,
    Name: "UserActivityLog6",
    VesselId: 6,
    VesselName: "Vessel6",
    ActionId: 2,
    ActionName: "B",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription6",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 7,
    Name: "UserActivityLog7",
    VesselId: 7,
    VesselName: "Vessel7",
    ActionId: 1,
    ActionName: "A",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription7",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 8,
    Name: "UserActivityLog8",
    VesselId: 8,
    VesselName: "Vessel8",
    ActionId: 2,
    ActionName: "B",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription8",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 9,
    Name: "UserActivityLog9",
    VesselId: 9,
    VesselName: "Vessel9",
    ActionId: 1,
    ActionName: "A",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription9",
    VesselProgramSprintName: "VesselProgramSprint",
  },
  {
    Id: 10,
    Name: "UserActivityLog10",
    VesselId: 10,
    VesselName: "Vessel10",
    ActionId: 2,
    ActionName: "B",
    ActivityTimestamp: "Asia",
    ActivityDescription: "ActivityDescription10",
    VesselProgramSprintName: "VesselProgramSprint",
  },
];

@Injectable()
export class ReportService {
  getReports(): Reports[] {
    return report;
  }
  getFleetOverviewReports(): FleetOverviewReport[] {
    return FleetOverview;
  }
  getAuditPreparationReports(): AuditPreparationReport[] {
    return AuditPreparation;
  }
  getDefectAnalysisReports(): DefectAnalysisReport[] {
    return DefectAnalysis;
  }
  getCrewPerformanceReports(): CrewPerformanceReport[] {
    return CrewPerformance;
  }
  getHistoricalAuditReports(): HistoricalAuditReport[] {
    return HistoricalAudit;
  }
  getUserActivityLogReports(): UserActivityLogReport[] {
    return UserActivityLog;
  }
}
