import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Tabs } from "../../shared/Models/Tab";
import { User } from "../../shared/Models/User";
import { Role } from "../../shared/Models/Role";
import notify from "devextreme/ui/notify";

@Component({
  templateUrl: "user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  companies: Company[] = [
    {
      ID: 1,
      DepartmentName: "Human Resources/ Clerical",
      DataSource: "dataSourceActive",
    },
    {
      ID: 2,
      DepartmentName: "Property Management",
      DataSource: "dataSourceActive",
    },
    {
      ID: 3,
      DepartmentName: "Sales Management",
      DataSource: "dataSourceActive",
    },
    {
      ID: 4,
      DepartmentName: "Accounts",
      DataSource: "dataSourceActive",
    },
    {
      ID: 5,
      DepartmentName: "Marketing",
      DataSource: "dataSourceActive",
    },
  ];
  dataSourceActive: User[];
  dataSourceGA: User[];
  dataSourcePM: User[];
  dataSourceSale: User[];
  dataSourceAcc: User[];
  dataSourceMkt: User[];

  dataSourcePending: User[];
  dataSourceGAPending: User[];
  dataSourcePMPending: User[];
  dataSourceSalePending: User[];
  dataSourceAccPending: User[];
  dataSourceMktPending: User[];

  dataSourceGACount: any;
  dataSourcePMCount: any;
  dataSourceSaleCount: any;
  dataSourceAccCount: any;
  dataSourceMktCount: any;

  dataSourcePendingCount: any;
  dataSourceGAPendingCount: any;
  dataSourcePMPendingCount: any;
  dataSourceSalePendingCount: any;
  dataSourceAccPendingCount: any;
  dataSourceMktPendingCount: any;

  businessPartners: any;
  independentContractor: any;
  employees: any;
  lics: any;
  licsCount: any;
  businessPartnersCount: any;
  independentContractorCount: any;

  currentEmployee: User = new User();
  addPopupVisible: boolean;
  editPopupVisible: boolean;
  toolbaritems: any[];
  urlBsinfo = "api/user";
  roleApi = "api/role";
  userImage: any;
  imageData: any;
  userImageUrl: any;
  defaultUserImage: string = "assets/no-user-img.jpg";
  urlPersonalInfo = "api/personalinfo";
  userInfoData: User[];
  userDetail: any;
  editstatus: boolean;
  roles: Role[];
  roleName: any;
  jobRoles: Role[] = [
    {
      id: 1,
      Name: "Associate",
    },
    {
      id: 2,
      Name: "Representative",
    },
    {
      id: 3,
      Name: "Supervisor",
    },
  ];
  departments: Role[] = [
    {
      id: 1,
      Name: "Human Resources/ Clerical",
    },
    {
      id: 2,
      Name: "Property Management",
    },
    {
      id: 3,
      Name: "Sales Management",
    },
    {
      id: 4,
      Name: "Accounts",
    },
    {
      id: 5,
      Name: "Marketing",
    },
  ];

  workInProgressPopupVisible: boolean;
  deleteUserPopupVisible: boolean;
  noOfUsers: number;
  activeUsers: number;
  class1Agent: any[] = [];
  currentRoute: "UserManagement";

  ngOnInit() {
    this.toolbar();
    this.loadUserInfo();
    this.editPopupVisible = false;
    this.addPopupVisible = false;
    this.deleteUserPopupVisible = false;
    this.loadClass1AgentInfo();
  }

  loadClass1AgentInfo() {
    this.http.get(this.urlBsinfo + "/Class1Agents").subscribe(
      (data: User[]) => {
        if (data) {
          this.class1Agent = data;
          this.class1Agent.map(function (item) {
            if (item.image) item.image = "data:image/jpeg;base64," + item.image;
          });
        }
      },
      (err) => {
      }
    );
  }

  constructor(private http: HttpClient, public _router: Router) {
    this.companies = this.companies;
    this.showEditUserInfoPopup = this.showEditUserInfoPopup.bind(this);
    this.showAddUserInfoPopup = this.showAddUserInfoPopup.bind(this);
    this.showDeleteUserInfoPopup = this.showDeleteUserInfoPopup.bind(this);
    this.userImageUrl = this.defaultUserImage;
    this.http.get(this.roleApi).subscribe((roles: Role[]) => {
      this.roles = roles;
    }),
      catchError((error) => {
        return throwError("Something went wrong");
      });
    this.editstatus = false;
  }

  showEditUserInfoPopup(e: any) {
    this.editstatus = false;
    this.userDetail = null;
    this.userDetail = e.row.data;
    this.editPopupVisible = true;
    this.userImageUrl = e.row.data.image;
  }

  uploadUserImage(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      notify("Only images are supported", "success", 600);
      //this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.userImageUrl = reader.result;
    };
    this.userImage = files[0];
    this.getBase64(this.userImage).then((v) => {
      this.userDetail.image = v;
      this.userDetail.imageData = v;
    });
  }

  showAddUserInfoPopup() {
    this.userImageUrl = this.defaultUserImage;
    this.userImage = null;
    this.http.get(this.urlPersonalInfo).subscribe((data: any) => {
      if (data.userRestrict) {
        notify("User Subscription has reached the limit", "error", 1500);
      } else {
        this.userDetail = new User();
        this.addPopupVisible = true;
      }
    });
  }

  showDeleteUserInfoPopup(e: any) {
    this.userDetail = null;
    this.userDetail = e.row.data;
    this.deleteUserPopupVisible = true;
  }

  cancelDeleteUserPopup() {
    this.deleteUserPopupVisible = false;
  }

  editUserDetails() {
    this.editstatus = true;
  }

  closeAddEditUserPopup() {
    this.addPopupVisible = false;
    this.editPopupVisible = false;
  }

  loadUserInfo() {
    this.http.get(this.urlBsinfo).subscribe((data: User[]) => {
      this.userInfoData = data;
      this.userInfoData.map(function (item) {
        if (item.image) item.image = "data:image/jpeg;base64," + item.image;
      });

      this.dataSourceActive = this.userInfoData.filter(function (user) {
        return user.isActive;
      });
      this.businessPartners = this.dataSourceActive.filter(function (user) {
        return user.isBusinessPartner;
      });
      this.independentContractor = this.dataSourceActive.filter(function (
        user
      ) {
        return user.isIndividualContractor;
      });
      this.employees = this.dataSourceActive.filter(function (user) {
        if (
          ((user.isBusinessPartner == false &&
            user.isIndividualContractor == false) ||
            (user.isBusinessPartner == null &&
              user.isIndividualContractor == null)) &&
          user.role != "Class 1 - Licensee in Charge"
        ) {
          return user;
        }
      });
      this.lics = this.dataSourceActive.filter(function (user) {
        if (
          ((user.isBusinessPartner == false &&
            user.isIndividualContractor == false) ||
            (user.isBusinessPartner == null &&
              user.isIndividualContractor == null)) &&
          user.role == "Class 1 - Licensee in Charge"
        ) {
          return user;
        }
      });

      this.dataSourceGA = this.employees.filter(function (user) {
        if (
          user.department == "Human Resource" ||
          user.department == "Human Resource/ Clerical"
        ) {
          return user.department;
        }
      });
      this.dataSourcePM = this.employees.filter(function (user) {
        if (user.department == "Property Management") {
          return user.department;
        }
      });
      this.dataSourceSale = this.employees.filter(function (user) {
        if (
          user.department == "Sales" ||
          user.department == "Sales Management"
        ) {
          return user.department;
        }
      });
      this.dataSourceAcc = this.employees.filter(function (user) {
        if (user.department == "Accounts") {
          return user.department;
        }
      });
      this.dataSourceMkt = this.employees.filter(function (user) {
        if (user.department == "Marketing") {
          return user.department;
        }
      });

      this.dataSourcePending = this.userInfoData.filter(function (user) {
        return !user.isActive;
      });
      this.dataSourceGAPending = this.dataSourcePending.filter(function (user) {
        if (
          user.department == "Human Resource" ||
          user.department == "Human Resource/ Clerical"
        ) {
          return user.department;
        }
      });
      this.dataSourcePMPending = this.dataSourcePending.filter(function (user) {
        if (user.department == "Property Management") {
          return user.department;
        }
      });
      this.dataSourceSalePending = this.dataSourcePending.filter(function (
        user
      ) {
        if (
          user.department == "Sales" ||
          user.department == "Sales Management"
        ) {
          return user.department;
        }
      });
      this.dataSourceAccPending = this.dataSourcePending.filter(function (
        user
      ) {
        if (user.department == "Accounts") {
          return user.department;
        }
      });
      this.dataSourceMktPending = this.dataSourcePending.filter(function (
        user
      ) {
        if (user.department == "Marketing") {
          return user.department;
        }
      });
      this.licsCount = this.lics.length;
      this.businessPartnersCount = this.businessPartners.length;
      this.independentContractorCount = this.independentContractor.length;

      this.dataSourceGACount = this.dataSourceGA.length;
      this.dataSourcePMCount = this.dataSourcePM.length;
      this.dataSourceSaleCount = this.dataSourceSale.length;
      this.dataSourceAccCount = this.dataSourceAcc.length;
      this.dataSourceMktCount = this.dataSourceMkt.length;

      this.dataSourcePendingCount = this.dataSourcePending.length;
      this.dataSourceGAPendingCount = this.dataSourceGAPending.length;
      this.dataSourcePMPendingCount = this.dataSourcePMPending.length;
      this.dataSourceSalePendingCount = this.dataSourceSalePending.length;
      this.dataSourceAccPendingCount = this.dataSourceAccPending.length;
      this.dataSourceMktPendingCount = this.dataSourceMktPending.length;
      this.noOfUsers = data.length;
      this.activeUsers = this.dataSourceActive.length;
    }),
      catchError((error) => {
        return throwError("Something went wrong");
      });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^.*,/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      } else {
        resolve(null);
      }
    });
  }

  addUserInfo() {
    if (this.userImage) {
      this.getBase64(this.userImage).then((v) => {
        this.userDetail.image = v;
        var roleId = this.userDetail.roleId;
        this.roleName = this.roles.filter(function (user) {
          if (user.id == roleId) {
            return user;
          }
        });
        this.userDetail.role = this.roleName[0].name;
        if (this.userDetail.role == "Business Partners/ Associates") {
          this.userDetail.isBusinessPartner = true;
          this.userDetail.isIndividualContractor = false;
        } else if (this.userDetail.role == "Independent Contractor") {
          this.userDetail.isBusinessPartner = false;
          this.userDetail.isIndividualContractor = true;
        } else {
          this.userDetail.isBusinessPartner = false;
          this.userDetail.isIndividualContractor = false;
        }
        this.http
          .post(this.urlBsinfo, this.userDetail)
          .subscribe((response) => {
            this.editstatus = true;
            this.closeAddEditUserPopup();
            this.loadUserInfo();
          }),
          catchError((error) => {
            return throwError("Something went wrong");
          });
      });
    } else {
      this.userDetail.image = null;

      var roleId = this.userDetail.roleId;
      this.roleName = this.roles.filter(function (user) {
        if (user.id == roleId) {
          return user;
        }
      });
      this.userDetail.role = this.roleName[0].name;

      if (this.userDetail.role == "Business Partners/ Associates") {
        this.userDetail.isBusinessPartner = true;
        this.userDetail.isIndividualContractor = false;
      } else if (this.userDetail.role == "Independent Contractor") {
        this.userDetail.isBusinessPartner = false;
        this.userDetail.isIndividualContractor = true;
      } else {
        this.userDetail.isBusinessPartner = false;
        this.userDetail.isIndividualContractor = false;
      }
      this.http.post(this.urlBsinfo, this.userDetail).subscribe((response) => {
        this.editstatus = true;
        this.closeAddEditUserPopup();
        this.loadUserInfo();
      }),
        catchError((error) => {
          return throwError("Something went wrong");
        });
    }
  }

  updateUserInfo() {
    if (this.userImage) {
      this.getBase64(this.userImage).then((v) => {
        this.userDetail.image = v;
        this.userDetail.imageData = v;

        if (this.userDetail.role == "Business Partners/ Associates") {
          this.userDetail.isBusinessPartner = true;
          this.userDetail.isIndividualContractor = false;
        } else if (this.userDetail.role == "Independent Contractor") {
          this.userDetail.isBusinessPartner = false;
          this.userDetail.isIndividualContractor = true;
        } else {
          this.userDetail.isBusinessPartner = false;
          this.userDetail.isIndividualContractor = false;
        }
        this.http
          .put(this.urlBsinfo + "/" + this.userDetail.id, this.userDetail)
          .subscribe((response) => {
            this.editstatus = true;
            this.closeAddEditUserPopup();
            this.loadClass1AgentInfo();
            this.loadUserInfo();
          }),
          catchError((error) => {
            return throwError("Something went wrong");
          });
      });
    } else {
      this.userDetail.image = null;

      if (this.userDetail.role == "Business Partners/ Associates") {
        this.userDetail.isBusinessPartner = true;
        this.userDetail.isIndividualContractor = false;
      } else if (this.userDetail.role == "Independent Contractor") {
        this.userDetail.isBusinessPartner = false;
        this.userDetail.isIndividualContractor = true;
      } else {
        this.userDetail.isBusinessPartner = false;
        this.userDetail.isIndividualContractor = false;
      }
      this.http
        .put(this.urlBsinfo + "/" + this.userDetail.id, this.userDetail)
        .subscribe((response) => {
          this.editstatus = true;
          this.closeAddEditUserPopup();
          this.loadUserInfo();
          this.loadClass1AgentInfo();
        }),
        catchError((error) => {
          return throwError("Something went wrong");
        });
    }
  }

  deleteUser() {
    this.http
      .delete(this.urlBsinfo + "/" + this.userDetail.id)
      .subscribe((response) => {
        this.editstatus = true;
        this.deleteUserPopupVisible = false;
        this.loadUserInfo();
        this.loadClass1AgentInfo();
      }),
      catchError((error) => {
        return throwError("Something went wrong");
      });
  }

  inviteClicked() {
    this.workInProgressPopupVisible = true;
  }

  toolbar() {
    this.toolbaritems = [
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-cursor'><b>Home / </b></div>";
        },
        onClick: () => {
          this._router.navigate(["/home"]);
        },
      },
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-label'><b>User Management</b></div>";
        },
      },
    ];
  }

  tabs: Tabs[] = [
    {
      ID: 1,
      title: "Active Users",
      template: "template1",
    },
    {
      ID: 2,
      title: "Inactive Users",
      template: "template2",
    },
  ];
}

export class Company {
  ID: number;
  DepartmentName: string;
  DataSource: string;
}
