import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Question, Sprint } from "src/app/shared/Models/Sprint";
import { ProgramService } from "../../program.service";

@Component({
  selector: "chapter-question",
  templateUrl: "./chapter-question.component.html",
  styleUrl: "./chapter-question.component.css",
})
export class ChapterQuestionComponent implements OnInit, AfterViewInit {
  @Input() sprintData: any = {};
  questions: any[] = [];
  expanded: Boolean = true;
  expandedRowKeys: Number[] = [];
  constructor(
    private programservice: ProgramService,
    private _router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }
  concatenateFields = (data: any) => {
    const shortText = data.question.shortText || '';  // Handle null or undefined values
    const referenceNumber = data.question.referenceNumber || ''+" ";
    return ` ${referenceNumber}${shortText}`;  // Concatenate with a space or any other separator
  };


}
