import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Program } from 'src/app/shared/Models/Program';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { Sprint } from 'src/app/shared/Models/Sprint';
import { environment } from 'src/environments/environment';



@Injectable()
export class ProgramService {
  constructor(private http: HttpClient) {}
  private token: string | null = null;
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  private toastr: ToastrService;

  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }

  getProgram() {
    return this.http.get<any>(
      this.apiURL + 'Program/GetProgram',
      this.options
    );
  }
  saveData(requestBody: any) {
    return this.http.post<any[]>(
      this.apiURL + 'Program/AddProgram',
      requestBody,
      this.options
    );
  }
  getSprint(id: any) {
    return this.http.post<any[]>(
      this.apiURL + 'Sprint/GetSprint/{id}',
      id,
      this.options
    );
  }
  getChecklistQuestions(questionId: any) {
    return this.http.get<any[]>(
      this.apiURL + 'CheckListQuestion/GetChecklistQuestions/' + questionId,
      this.options
    );
  }
  GetChapterWiseSprintQuestions(id: any) {
    return this.http.post<ServerResponse>(
      this.apiURL + 'Sprint/GetChapterWiseSprintQuestions/{sprintid}',
      id,
      this.options
    );
  }

  AddCustomeChecklistQuestion(requestBody: any) {
    return this.http.post<any[]>(
      this.apiURL + 'CheckListQuestion/AddCustomQuestions',
      requestBody,
      this.options
    );
  }
  deleteRow(chapterId: number) {
    // Replace with your delete API URL
    const deleteUrl = `${this.apiURL}Program/${chapterId}`;
    return this.http.delete(deleteUrl,
      this.options);
  }

}
