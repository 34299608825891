import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { VesselService } from "../../../shared/services/vessels.service";
import { ProgramService } from "../program.service";
import { Program } from "src/app/shared/Models/Program";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: "app-program-add",
  templateUrl: "./add.component.html",
  styleUrl: "./add.component.css",
})
export class AddComponent implements OnInit {
  VesselOptions: any[];
  selectedType: any;
  program: Program = new Program();
  programForm: FormGroup;
  @Input() isShowAddForm: boolean;
  @Output() isShowAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private programservice: ProgramService,
    private vesselservice: VesselService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _spinner: SpinnerService,
  ) {}
  ngOnInit(): void {
    this.programForm = this.formBuilder.group({
      Name: ["", Validators.required],
      PlannedStartDate: [null, Validators.required],
      VesselId: ["", Validators.required],
    });

    this.vesselservice.getVessels().subscribe((res) => {
      this.VesselOptions = res.map((vessel) => ({
        id: vessel.id,
        name: vessel.name,
      }));
    });
  }
  getCurrentDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  }
  closeAddProgramPopup() {
    this.isShowAddForm = false;
    this.isShowAddFormChange.emit(this.isShowAddForm);
  }
  onTypeSelectionChanged(selectedValue: any) {
    this.selectedType = selectedValue;
  }
  onProgramAdd() {
    this._spinner.showLoader();
    this.programservice.saveData(this.programForm.value).subscribe(
      (response) => {
        this._spinner.hideLoader();
        this.closeAddProgramPopup();
      },
      (error) => {
        this._spinner.hideLoader();
        this.toastr.error(error.message);
      }
    );
  }
}
