import { Component } from "@angular/core";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";
import { DefectAnalysisReport } from "src/app/shared/Models/DefectAnalysisReport";

@Component({
  selector: "app-defect-analysis",
  templateUrl: "./defect-analysis.component.html",
  styleUrl: "./defect-analysis.component.css",
})
export class DefectAnalysisComponent {
  DefectAnalysisOptions: DefectAnalysisReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.DefectAnalysisOptions = reportservice.getDefectAnalysisReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
