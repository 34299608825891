import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VesselService } from "../../shared/services/vessels.service";
import { Vessel } from "src/app/shared/Models/Vessel";
import CustomStore from "devextreme/data/custom_store";
import { VesselType } from "src/app/shared/Models/VesselType";

@Component({
  selector: "app-vessels",
  templateUrl: "./vessels.component.html",
  styleUrl: "./vessels.component.css",
})
export class VesselsComponent implements OnInit {
  Vessel: Vessel[];
  showAddForm: boolean = false;
  showEditForm: boolean = false;
  dataSource: CustomStore;
  vesselTypes:VesselType[]=[];
  constructor(public vesselservice: VesselService, public _router: Router) {

    this.dataSource = new CustomStore({
      key: 'OrderID',
      load: () => "this.sendRequest(`${URL}/Orders`)",
      remove: (key):any => alert(key)
    });
  }
  ngOnInit() {
    this.getTypeOptions()
    this.onCloneIconClick = this.onCloneIconClick.bind(this);
  }
  onCloneIconClick(e) {
    const clonedItem = { ...e.row.data };
    this.Vessel.splice(e.row.rowIndex, 0, clonedItem);
  }
  isDeleteIconVisible(clickedId: string) {
    // this.vesselservice.deleteData(clickedId).subscribe(
    //   (response) => {
    //   },
    //   (error) => {
    //     console.error("Error status:", error.status);
    //     console.error("Error message:", error.message);
    //   }
    // );
    return true;
  }
  showAddVesselPopup() {
    this.showAddForm = true;
  }
  showEditVesselPopup() {
    this.showEditForm = true;
  }
  deleteRow(){
    alert("hi");
  }
  onRowRemoving(e: any): void {
    const rowData = e.data; // Get the data of the row being removed
   console.log(e.data);
   this.vesselservice.deleteData(e.data.id).subscribe((res=>{

   }));
  }
  closeAddPopup(e: any) {
    // tslint:disable-next-line:triple-equals
    if (e == '2') {
      this.getTypeOptions();
    }
    this.showAddForm = false;
  }
  getTypeOptions() {
    this.vesselservice.getVesselType().subscribe((vessels: any[]) => {
      this.vesselTypes=vessels;
      this.vesselservice.getVessels().subscribe(
        (response) => {
          this.Vessel=response;
          if(this.Vessel.length>0){
            this.Vessel.forEach(x=>{
              x.typeName=this.vesselTypes.find(t=>t.id==x.vesselTypeId).name;
            })
          }
        },
        (error) => {
        }
      );
    });
  }
}
