// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .calendar-with-border {
  border: 1px solid #000;
}

::ng-deep .calendar-with-border .dx-calendar-cell {
  border: 1px solid #000; 
}
::ng-deep .dx-button-mode-text.dx-button-default {
  color: #ffffff; 
}
 ::ng-deep .dx-button-has-text .dx-button-content{
  background-color: #000;
}

::ng-deep .dx-icon-chevronleft:before{
color: #ffffff;
}

::ng-deep .dx-icon-chevronright:before{
  color: #ffffff;
}
::nd-deep .dx-widget .dx-button .dx-button-mode-text .dx-button-default .dx-button-has-text .dx-calendar-caption-button .dx-button-content .dx-inkripple{
background-color: #000;
} */`, "",{"version":3,"sources":["webpack://./src/app/pages/sire-calendar/sire-calendar.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;GAuBG","sourcesContent":["/* .calendar-with-border {\n  border: 1px solid #000;\n}\n\n::ng-deep .calendar-with-border .dx-calendar-cell {\n  border: 1px solid #000; \n}\n::ng-deep .dx-button-mode-text.dx-button-default {\n  color: #ffffff; \n}\n ::ng-deep .dx-button-has-text .dx-button-content{\n  background-color: #000;\n}\n\n::ng-deep .dx-icon-chevronleft:before{\ncolor: #ffffff;\n}\n\n::ng-deep .dx-icon-chevronright:before{\n  color: #ffffff;\n}\n::nd-deep .dx-widget .dx-button .dx-button-mode-text .dx-button-default .dx-button-has-text .dx-calendar-caption-button .dx-button-content .dx-inkripple{\nbackground-color: #000;\n} */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
