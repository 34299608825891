import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaptainService } from 'src/app/shared/services/captain.service';

@Component({
  selector: 'app-manage-question',
  templateUrl: './manage-question.component.html',
  styleUrl: './manage-question.component.css'
})
export class ManageQuestionComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _captainService:CaptainService

  )
  {

  }
  id:number;
  checklistData :any[]=[];
  showReAssignPopup: boolean = false;

  onEdit(event: any) {
    // Handle edit action
    console.log('Edit:', event);
  }

  onDelete(event: any) {
    // Handle delete action
    console.log('Delete:', event);
  }
  ngOnInit() {
    this._route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.getCrewAssignedChecklistQuestion(this.id);
  }
  getCrewAssignedChecklistQuestion(sprintQuestionId:number){
    this._captainService.crewChecklistQuestion(sprintQuestionId).subscribe((res=>{
      this.checklistData=res;

    }))
  }
  confirmReAssign() {
    // Logic to re-assign crew with this.newCrewName
    console.log('Re-assigning to crew:');

    // Close the popup
    this.closeReAssignPopup();
  }

  closeReAssignPopup() {
    this.showReAssignPopup = false;
  }
  openReAssignPopup(item: any) {
    this.showReAssignPopup = true;
  }
  approveCrewAnswer(item :any){
    this._captainService.approveCrewAnswer(item.id).subscribe((res=>{
      console.log(res);
    }))

  }
}
