import { CheckList } from "./CheckList";

export class Sprint {
  Id: number;
  Name: string;
  PlannedStartDate: string;
  ActualStartDate: string;
  Status: string;
}

export class Question {
  Id: number;
  ChapterName: string;
  Name: string;
  FullName: string;
  Status: string;
  CheckList: CheckList[];
}

export class Chapter {
  Id: number;
  name: string;
  Questions?: Question[];
  referenceNumber:string;
}
