import { Component } from "@angular/core";
import { Reports } from "src/app/shared/Models/Reports";
import { ReportService } from "./report.service";
import { Router } from "@angular/router";
import { FleetOverviewReport } from "src/app/shared/Models/FleetOverviewReport";
import { UserActivityLogReport } from "src/app/shared/Models/UserActivityLogReport";
import { HistoricalAuditReport } from "src/app/shared/Models/HistoricalAuditReport";
import { CrewPerformanceReport } from "src/app/shared/Models/CrewPerformanceReport";
import { DefectAnalysisReport } from "src/app/shared/Models/DefectAnalysisReport";
import { AuditPreparationReport } from "src/app/shared/Models/AuditPreparationReport";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrl: "./report.component.css",
})
export class ReportComponent {
  ReportsOptions: string[];
  report: Reports = new Reports();

  constructor(public reportservice: ReportService, public router: Router) {
    this.ReportsOptions = this.reportservice
      .getReports()
      .map((report) => report.Name);
  }
  showDetails(type: string) {
    switch (type) {
      case "Fleet Overview":
        this.router.navigate(["/report/fleet-overview"]);
        break;
      case "Audit Preparation Status":
        this.router.navigate(["/report/audit-preparation"]);
        break;
      case "Defect Analysis Report":
        this.router.navigate(["/report/defect-analysis"]);
        break;
      case "Crew Performance Report":
        this.router.navigate(["/report/crew-performance"]);
        break;
      case "Historical Audit Data":
        this.router.navigate(["/report/historical-audit-data"]);
        break;
      case "User Activity Log":
        this.router.navigate(["/report/user-activity-log"]);
        break;
      default:
        this.router.navigate(["/report"]);
        break;
    }
  }
}
