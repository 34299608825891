import { ChangeDetectorRef, Component } from "@angular/core";
import { SireCalenderService } from "./sire-calendar.service";
import { Events } from "src/app/shared/Models/Events";

@Component({
  selector: "app-sire-calendar",
  templateUrl: "./sire-calendar.component.html",
  styleUrls: ["./sire-calendar.component.css"],
})
export class SireCalendarComponent {
  Event: Events[];
  showAddForm: boolean = false;
  currentDate: Date = new Date();

  constructor(
    private calenderservice: SireCalenderService,
    private cdr: ChangeDetectorRef
  ) {
    this.Event = calenderservice.getEvent();
  }
  showAddEventPopup() {
    this.showAddForm = true;
  }
  isDeleteIconVisible(e) {
    return true;
  }
  onCellDoubleClick(event: any) {
    this.showAddEventPopup();
    this.cdr.detectChanges();
  }
}
