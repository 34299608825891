import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginFormComponent } from "./shared/components";
import { AuthGuardService } from "./shared/services";
import { HomeComponent } from "./pages/home/home.component";
import { SireCalendarComponent } from "./pages/sire-calendar/sire-calendar.component";
import { UserManagementComponent } from "./pages/user-management/user-management.component";
import { VesselsComponent } from "./pages/vessels/vessels.component";
import { VesselCrewComponent } from "./pages/vessel-crew/vessel-crew.component";
import { ProgramComponent } from "./pages/program/program.component";
import { ReportComponent } from "./pages/report/report.component";
import { DetailsComponent } from "./pages/program/sprints/sprints.component";
import { FleetOverviewComponent } from "./pages/report/fleet-overview/fleet-overview.component";
import { AuditPreparationComponent } from "./pages/report/audit-preparation/audit-preparation.component";
import { DefectAnalysisComponent } from "./pages/report/defect-analysis/defect-analysis.component";
import { CrewPerformanceComponent } from "./pages/report/crew-performance/crew-performance.component";
import { HistoricalAuditDataComponent } from "./pages/report/historical-audit-data/historical-audit-data.component";
import { UserActivityLogComponent } from "./pages/report/user-activity-log/user-activity-log.component";
import { ChecklistComponent } from "./pages/program/checklist/checklist.component";
import { QuestionComponent } from "./pages/question/question.component";
import { CaptainComponent } from "./captain-managment/captain.component";
import { ManageQuestionComponent } from "./captain-managment/manage-question/manage-question.component";
import { CrewQuestionComponent } from "./pages/crew/crew-question/crew-question.component";
import { CrewChecklistQuestionsComponent } from "./pages/crew/crew-checklist-questions/crew-checklist-questions.component";

const routes: Routes = [
  {
    path: "user-management",
    component: UserManagementComponent,
  },
  {
    path: "account-information",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "sire-calendar",
    component: SireCalendarComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "login",
    component: LoginFormComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "vessel",
    component: VesselsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:'vessel-crew/:id',
    component: VesselCrewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "program",
    children: [
      {
        path: "",
        component: ProgramComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: ":programid/sprints",
        component: DetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "checklist",
        component: ChecklistComponent,
        canActivate: [AuthGuardService],
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/fleet-overview",
    component: FleetOverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/audit-preparation",
    component: AuditPreparationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/defect-analysis",
    component: DefectAnalysisComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/crew-performance",
    component: CrewPerformanceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/historical-audit-data",
    component: HistoricalAuditDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "report/user-activity-log",
    component: UserActivityLogComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "question/:questionid",
    component: QuestionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-vessel-questionnaire',
    children :[
      {

        path:"",
        component:CaptainComponent,
        canActivate: [AuthGuardService],
      },
      {
        path:':id',
        component:ManageQuestionComponent,
        canActivate: [AuthGuardService],
      }
    ]
  },
  {
    path:'crew-questions',
    component:CrewQuestionComponent,
    canActivate: [AuthGuardService],
  },
  {
     path: 'crew-checklist-question/:id',
     component: CrewChecklistQuestionsComponent,
     canActivate: [AuthGuardService],
  },
  // { manage-vessel-questionnaire/:id
  //   path: "report",
  //   children: [
  //     {
  //       path: "",
  //       component: ReportComponent,
  //     },

  //     {
  //       path: "fleet-overview",
  //       component: FleetOverviewComponent,
  //     },
  //   ],
  //   canActivate: [AuthGuardService],
  // },

  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
