import { Form } from "formiojs";
import { Chapter } from "./Sprint";

export class QuestionDto{
  id: number;
  text: string;
  shortText: string;
  chapterId: number;
  roviq: string;
  objective: string;
  industryGuidance: string;
  inspectionGuidance: string;
  suggestedInspectorAction: string;
  expectedEvidence: string;
  groundsForNegativeObservation: string;
  referenceNumber:string;
  chapter:Chapter= new Chapter();
}
