import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * Injectable service
 * @export
 * @class SpinnerService
 */
@Injectable()
export class SpinnerService {

  /**
   * @description spinners BehaviorSubject
   * @type {BehaviorSubject<any>}
   * @memberof Ng4LoadingSpinnerService
   */
  public spinnerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  /**
   * Creates an instance of Ng4LoadingSpinnerService.
   * @memberof Ng4LoadingSpinnerService
   */
  constructor() {

  }

  /**
   * To show spinner
   * @memberof Ng4LoadingSpinnerService
   */
  showLoader() {
    this.spinnerSubject.next(true);
  }

  /**
   * To hide spinner
   * @memberof Ng4LoadingSpinnerService
   */
  hideLoader() {
    this.spinnerSubject.next(false);
  }

  getMessage(): Observable<any> {
    return this.spinnerSubject.asObservable();
  }
}

