import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ProgramService } from "../program/program.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "question",
  templateUrl: "./question.component.html",
  styleUrl: "./question.component.css",
})
export class QuestionComponent implements OnInit {
  @Input() questionId: number;
  question: any[];
  questionid: any;
  ChapterName: string;
  QuestionName: string;
  expanded: Boolean = true;
  expandedRowKeys: Number[] = [];
  showPopup: boolean = false;
  sprintId: any;
  checklistId: any;
  customchecklistId: any;
  QuestionId: any;
  checklistQuestion: FormGroup;
  referencePopupVisible:boolean=false;
  form:any;
  currentMaterialIndex: number = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private programservice: ProgramService,
    private fb: FormBuilder
  ) {
    this.checklistQuestion = this.fb.group({
      Name: ["", Validators.required],
      SprintId: ["", Validators.required],
      QuestionId: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.questionId = +params["questionid"];
    });
    this.getQuestionList();
  }
  getQuestionList() {
    this.programservice.getChecklistQuestions(this.questionId).subscribe(
      (response) => {
        this.question = response;
        for (let i = 0; i < response.length; i++) {
          this.checklistId = response[i].checklistId;
          this.customchecklistId = response[i].customChecklistId;
        }
        this.QuestionName =response[0].sprintQuestion.question.referenceNumber +" " +  response[0].sprintQuestion.question.shortText;
        this.sprintId = response[0].sprintQuestion.sprintId;
        this.ChapterName=response[0].sprintQuestion.question.chapter.referenceNumber+" "+ response[0].sprintQuestion.question.chapter.name;
        this.QuestionId = response[0].sprintQuestion.questionId;
      },
      (error) => {
        console.error("Error message:", error.message);
      }
    );
  }
  cancel(): void {
    this.showPopup = false;
  }
  OpenPopUp(): void {
    this.showPopup = true;
  }
  save(): void {
    const staticData = {
      Name: this.checklistQuestion.value.Name,
      SprintId: this.sprintId,
      QuestionId: this.QuestionId,
    };
    this.programservice.AddCustomeChecklistQuestion(staticData).subscribe(
      (response) => {
        this.getQuestionList();
      },
      (error) => {
        console.error("Error message:", error.message);
      }
    );
    this.cancel();
  }
  back() {
    //this._router.navigate(["/question"]);
    this._location.back();
  }
  openReferencePopup(){
    this.form=JSON.parse( this.question[0].sprintQuestion.question.roviq);
    this.referencePopupVisible = true;
  }
  submitForm(res:any){
    this.referencePopupVisible = false;
    this.currentMaterialIndex=0;
  }
  closeReferencePopup(){
    this.referencePopupVisible = false;
  }
  checklistNameTextAreaTemplate = (cellElement: any, cellInfo: any) => {
    const checklistName = cellInfo.value;

    // Create a textarea element with the checklist name
    cellElement.innerHTML = `<textarea rows="7" cols="20" style="width: 100%; resize: none; border: 1px solid #ccc; padding: 5px;" disabled>
                                ${checklistName}
                             </textarea>`;
  };

}
