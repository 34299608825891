// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
}

.dx-card {
  width: 330px;
  margin: auto auto;
  padding: 40px;
}
:host-context(.screen-x-small) .dx-card {
  width: auto;
  height: 100%;
  box-shadow: none;
  margin: auto 5%;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/single-card/single-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;AACJ","sourcesContent":[":host {\n  width: 100%;\n  height: 100%;\n}\n\n.dx-card {\n  width: 330px;\n  margin: auto auto;\n  padding: 40px;\n\n  :host-context(.screen-x-small) & {\n    width: auto;\n    height: 100%;\n    box-shadow: none;\n    margin: auto 5%;\n    border: 0;\n  }  \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
