import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private tabUpdateSource = new Subject<void>();
  tabUpdate$ = this.tabUpdateSource.asObservable();

  updateTabs() {
    this.tabUpdateSource.next();
  }
}
