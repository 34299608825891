// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-btn {
  display: inline-block;
  padding: 5px 10px;
  color: white;
  background-color: #007BFF;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.action-btn:hover {
  background-color: #0056b3;
}

.status-pending {
  color: red;
}

.status-completed {
  color: green;
}

.status-inprogress {
  color: yellow;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/crew/crew-question/crew-question.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".action-btn {\r\n  display: inline-block;\r\n  padding: 5px 10px;\r\n  color: white;\r\n  background-color: #007BFF;\r\n  text-decoration: none;\r\n  border-radius: 4px;\r\n  text-align: center;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n.action-btn:hover {\r\n  background-color: #0056b3;\r\n}\r\n\r\n.status-pending {\r\n  color: red;\r\n}\r\n\r\n.status-completed {\r\n  color: green;\r\n}\r\n\r\n.status-inprogress {\r\n  color: yellow;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
