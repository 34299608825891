import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/Models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VesselCrewService} from '../../../shared/services/vessel-crew.service';
import {CrewService} from '../../../shared/services/crewService';
import {VesselService} from '../../../shared/services/vessels.service';
import {ToastrService} from 'ngx-toastr';
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: "app-vesselcrew-edit",
  templateUrl: "./edit.component.html",
  styleUrl: "./edit.component.css",
})
export class EditComponent implements OnInit {
  @Input() isShowEditForm: boolean;
  vesselCrewForm: FormGroup;
  rankOptions: string[] = [
    'Captain',
    'First Officer',
    'Second Officer',
    'Engineer',
    'Deckhand',
  ];
  crew: User = new User();
  constructor(private vesselcrewservice: VesselCrewService,
              private _crewService: CrewService,
              private vesselservice: VesselService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private _spinner: SpinnerService,) {}
  ngOnInit() {
    this.vesselCrewForm = this.formBuilder.group({
      Name: ['', Validators.required],
    });
  }
}
