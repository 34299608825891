// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-spacing {
    margin-bottom: 30px; 
  }
  `, "",{"version":3,"sources":["webpack://./src/app/pages/program/checklist/checklist.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB","sourcesContent":[".toolbar-spacing {\n    margin-bottom: 30px; \n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
