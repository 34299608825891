import { Component, OnInit } from "@angular/core";
import { ProgramService } from "../program.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-checklist",
  templateUrl: "./checklist.component.html",
  styleUrl: "./checklist.component.css",
})
export class ChecklistComponent implements OnInit {
  constructor(
    private programservice: ProgramService,
    private _router: Router
  ) {}
  ngOnInit(): void {
    //this.getQuestionList();
  }
  // getQuestionList() {
  //   this.programservice.getChecklistQuestions().subscribe(
  //     (response) => {
  //     },
  //     (error) => {
  //       console.error("Error message:", error.message);
  //     }
  //   );
  // }
}
