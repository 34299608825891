// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-column {
  flex: 0 0 48%; /* Adjust the percentage as needed for spacing */
}

.toggle-password-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/vessel-crew/add/add.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa,EAAE,gDAAgD;AACjE;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".form-row {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.form-column {\r\n  flex: 0 0 48%; /* Adjust the percentage as needed for spacing */\r\n}\r\n\r\n.toggle-password-button {\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 10px;\r\n  background: none;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
