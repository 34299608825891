import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { VesselCrewService } from '../../shared/services/vessel-crew.service';
import { VesselCrews } from 'src/app/shared/Models/VesselCrew';
import {VesselService} from '../../shared/services/vessels.service';
import {VesselOption} from '../../shared/Models/VesselOption';
import {SpinnerService} from '../../shared/services/spinner.service';
import {User} from '../../shared/Models/User';
import {CrewService} from '../../shared/services/crewService';

@Component({
  selector: 'app-vessel-crew',
  templateUrl: './vessel-crew.component.html',
  styleUrl: './vessel-crew.component.css',
})
export class VesselCrewComponent implements OnInit {
  VesselCrew: VesselCrews[];
  crew: User = new User() ;
  VesselOptions: VesselOption[] = [];
  showAddForm: boolean;
  showEditForm: boolean;
  @Output() showAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  // rankOptions: string[] = [
  //   'Captain',
  //   'First Officer',
  //   'Second Officer',
  //   'Engineer',
  //   'Deckhand',
  // ];
  rankOptions:string[]=[
    'Master',
    'Chief Engineer',
    'Chief Officer',
    'Second Officer',
    'Second Engineer',
    'Third Officer',
    'Third Engineer',
    'Fourth Engineer',
    'Fifth Engineer',
    'Deck Cadet',
    'Electrician',
    'Motorman',
    'Chief Cook',
    'Able Body',
    'Pumpman',
    'Wiper',
    'Messman',
    'OS',
    'Bosun',
    'Welder',
    'Fitter'
  ]
  statusOptions = [true, false];

  constructor(
    private vesselcrewservice: VesselCrewService,
    private vesselservice: VesselService,
    public _router: Router,
    private _spinner: SpinnerService,
    private _crewService: CrewService
  ) {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
     }));

    this.onCloneIconClick = this.onCloneIconClick.bind(this);

  }
  ngOnInit() {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
     }));
    this.getVessselType();
    this.showAddForm = false;

  }

  onCloneIconClick(e) {
    const clonedItem = { ...e.row.data };
    this.VesselCrew.splice(e.row.rowIndex, 0, clonedItem);
  }

  isDeleteIconVisible(e) {
    return true;
  }
  showAddVesselCrewPopup() {
    this.showAddForm = true;
  }
  showEditVesselCrewPopup() {
    this.showEditForm = true;
  }
  showAddProgramPopup() {
    this.showAddForm = true;
    this.showAddFormChange.emit(this.showAddForm);
  }
  onShowAddFormChange(e: any) {
    this.showAddForm = false;
  }
  reloadCrew(e: any){
    this.getCrew();
  }
  getVessselType() {
    this.vesselservice.getVessels().subscribe((res) => {
      this.VesselOptions = res.map((vessel) => ({
        id: vessel.id,
        name: vessel.name,
      }));
    });
  }
  getCrew() {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
    }));
  }
  onRowRemoving(e: any) {
   this.vesselcrewservice.deleteData(e.data.id).subscribe((res) => {
     alert('Crew Removed!');
   }) ;
  }
  onRowUpdate(e: any) {
    this._spinner.showLoader();
    this.crew.designation = e.data.designation;
    this.crew.id = e.data.id;
    this.crew.vesselId = e.data.vessel.id;
    this.crew.firstName = e.data.firstName;
    this.crew.password = e.data.password;
    this.crew.isActive=e.data.isActive;
    this._crewService.addCrew(this.crew).subscribe((res) => {
        this._spinner.hideLoader();
      },
      (error) => {
        // Handle error respons
        this._spinner.hideLoader();
      }) ;
  }
  renderUserStatus(cellElement: any, cellInfo: any) {
    const isActive = cellInfo.value;
    const statusText = isActive ? 'Active' : 'Inactive';
    const color = isActive ? 'green' : 'red';

    // Apply the text and styling directly to the cell
    cellElement.innerHTML = `<span style="color: ${color};">${statusText}</span>`;
  }


}
