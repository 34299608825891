import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

@Injectable({ providedIn: "root" })
export class AuthService {
  loggedIn = false;
  private currentUserSubject: BehaviorSubject<string>;
  public currentUser: Observable<string>;
  response_data: object;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";

  constructor(
    private router: Router,
    private http: HttpClient,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.logOut();
      this.idleState = "Timed out!";
      alert("You are logged out due to inactivity for 15 minutes");
      this.timedOut = true;
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    // this.reset();

    this.currentUserSubject = new BehaviorSubject<string>(
      localStorage.getItem("currentUser")
    );
    this.currentUser = this.currentUserSubject.asObservable();
    if (localStorage.getItem("currentUser")) {
      this.loggedIn = true;
      this.idle.watch();
      this.timedOut = false;
      // this.http.get<any>('/api/login').subscribe(data => {
      //   this.loggedIn = true;
      // }, error => {
      //   this.loggedIn = false;
      // });
    } else {
      this.loggedIn = false;
    }
  }
  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  logIn(username: string, password: string, remember: boolean) {
    return this.http
      .post<any>(
        "/api/login",
        { username, password, remember }
      )
      .pipe(
        tap((next) => {
          localStorage.setItem("currentUser", next.token);
          localStorage.setItem("roleId", next.roleId);
          localStorage.setItem("vesselname", next.vesselName);
          localStorage.setItem("name",next.name);
          this.loggedIn = true;
          this.router.navigate(["/home"]);
          this.idle.watch();
          this.timedOut = false;
        }),
        finalize(() => {})
      );
  }

  logOut() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("roleId");
    this.loggedIn = false;
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }

  get isLoggedIn() {
    if(localStorage.getItem("currentUser")==null||undefined) return false;
    return true;
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    const isLoginForm = route.routeConfig.path === "login";

    if (isLoggedIn && isLoginForm) {
      this.router.navigate(["/"]);
      return false;
    }

    if (!isLoggedIn && !isLoginForm) {
      this.router.navigate(["/login"]);
    }

    return isLoggedIn || isLoginForm;
  }
}
