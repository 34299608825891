import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Events } from "src/app/shared/Models/Events";

export const Event: Events[] = [
  {
    Id: 1,
    Name: "Event1",
    StartDate: "2024-02-22",
    VesselId: 1,
    VesselName: "Vessel1",
  },
  {
    Id: 2,
    Name: "Event2",
    StartDate: "2024-02-23",
    VesselId: 2,
    VesselName: "Vessel2",
  },
  {
    Id: 3,
    Name: "Event3",
    StartDate: "2024-02-24",
    VesselId: 3,
    VesselName: "Vessel3",
  },
  {
    Id: 4,
    Name: "Event4",
    StartDate: "2024-02-25",
    VesselId: 4,
    VesselName: "Vessel4",
  },
  {
    Id: 5,
    Name: "Event5",
    StartDate: "2024-02-26",
    VesselId: 5,
    VesselName: "Vessel5",
  },
  {
    Id: 6,
    Name: "Event6",
    StartDate: "2024-02-27",
    VesselId: 6,
    VesselName: "Vessel6",
  },
  {
    Id: 7,
    Name: "Event7",
    StartDate: "2024-02-28",
    VesselId: 7,
    VesselName: "Vessel7",
  },
  {
    Id: 8,
    Name: "Event8",
    StartDate: "2024-02-29",
    VesselId: 8,
    VesselName: "Vessel8",
  },
  {
    Id: 9,
    Name: "Event9",
    StartDate: "2024-03-01",
    VesselId: 9,
    VesselName: "Vessel9",
  },
  {
    Id: 10,
    Name: "Event10",
    StartDate: "2024-03-02",
    VesselId: 10,
    VesselName: "Vessel10",
  },
];
@Injectable()
export class SireCalenderService {
  private toastr: ToastrService;
  getEvent(): Events[] {
    return Event;
  }
  saveData() {
    this.toastr.success("Data saved successfully!");
  }
}
