import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from '../program.service';
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: 'app-details',
  templateUrl: './sprints.component.html',
  styleUrl: './sprints.component.css',
})
export class DetailsComponent implements OnInit {
  sprints: any[] = [];
  originalSprints: any[] = [];
  programId: string;
  expanded: Boolean = true;
  expandedRowKeys: Number[] = [];
  sprintdatatoPass: any[] = [];
  constructor(
    private programservice: ProgramService,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.programId = params['programid'];
    });
    this.programservice.getSprint(this.programId).subscribe(
      (response) => {
        this.originalSprints = response; // Store original response
        this.sprints = response.map((sprint) => ({
          id: sprint.id,
          name: sprint.name,
          plannedStartDate: sprint.plannedStartDate,
          plannedEndDate: sprint.plannedEndDate,
          statusId: sprint.statusId,
          statusName: sprint.status.name,
        }));
        this.sprints.forEach((sprint) => {
          this.expandedRowKeys.push(sprint.id);
        });
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  BacktoPrograms() {
    this._router.navigate(['/program']);
  }

  bindChapterWiseQuestions(currentSprintClickedId: number) {
    const clickedSprint = this.originalSprints.find(
      (sprint) => sprint.id === currentSprintClickedId
    );
    if (clickedSprint) {
      this.sprintdatatoPass = clickedSprint;
    } else {
    }
    return this.sprintdatatoPass;
  }
  handleItemExpanded(e: any) {
    // tslint:disable-next-line:radix
    this._spinnerService.showLoader();
    this.programservice.GetChapterWiseSprintQuestions(parseInt(e.key)).subscribe(res => {
      this._spinnerService.hideLoader();
      this.sprintdatatoPass = res.result;
    });
  }
  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
       if (e.data.statusId === 6) {
        e.rowElement.style.backgroundColor = '#d4edda';  // Light red for inactive
      } else if (e.data.statusId === 7) {
        e.rowElement.style.backgroundColor = '#d4d4d4';  // Light grew for pending
      }
    }
  }
}
