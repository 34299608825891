import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { CrewService } from 'src/app/shared/services/crewService';
import { SharedService } from 'src/app/shared/services/sharedService';

@Component({
  selector: 'app-crew-question',
  templateUrl: './crew-question.component.html',
  styleUrl: './crew-question.component.css'
})
export class CrewQuestionComponent implements OnInit {
  constructor(private _crewService:CrewService,
    private _sharedService: SharedService){

  }
  crewQuestions:any[]=[];

  ngOnInit(){
    this._crewService.getCrewQuestion().subscribe((res:ServerResponse)=>{
      this.crewQuestions=res.result;
    });

  }

  vesselQuestions = [
    { id: 1, question: 'What is the maximum draft?', chapter: 'Chapter 1', crew: 'Crew A', status: 'Pending' },
    { id: 2, question: 'How often is maintenance performed?', chapter: 'Chapter 2', crew: 'Crew B', status: 'Completed' },
    { id: 3, question: 'Are all safety checks up to date?', chapter: 'Chapter 3', crew: 'Crew C', status: 'In Progress' }
  ];

  editQuestion(data: any) {
    // Implement the edit functionality here
    console.log('Edit question:', data);
  }
  click = (e) => {
    notify(`The button was clicked`);
  };

  getStatusClass(status: string): string {
    switch (status) {
      case 'Pending':
        return 'status-pending';
      case 'Completed':
        return 'status-completed';
      case 'In Progress':
        return 'status-inprogress';
      default:
        return '';
    }
  }
  check(id:string){
    this._sharedService.sendQuestionIdFromSprintQuestion(id);
  }
}
