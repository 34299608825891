import { Component } from "@angular/core";
import { HistoricalAuditReport } from "src/app/shared/Models/HistoricalAuditReport";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-historical-audit-data",
  templateUrl: "./historical-audit-data.component.html",
  styleUrl: "./historical-audit-data.component.css",
})
export class HistoricalAuditDataComponent {
  HistoricalAuditOptions: HistoricalAuditReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.HistoricalAuditOptions = reportservice.getHistoricalAuditReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
