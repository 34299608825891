/* tslint:disable */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramService } from './program.service';
import { Program } from 'src/app/shared/Models/Program';
import { Chapter, Sprint } from 'src/app/shared/Models/Sprint';
import { VesselService } from '../../shared/services/vessels.service';
import {SpinnerService} from '../../shared/services/spinner.service';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrl: './program.component.css',
})
export class ProgramComponent implements OnInit {
  isVisible = false;
  type = 'info';
  message:string;
  programs: any[] = [];
  VesselOptions: any[];
  showAddForm:boolean = false;
  showEditForm:boolean = false;

  @Output() showAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  constructor(
    private programservice: ProgramService,
    private vesselservice: VesselService,
    private _spinner: SpinnerService,
    private _router: Router
  ) {}
  ngOnInit(): void {

    this.getProgramList();
  }
  getProgramList() {
    this._spinner.showLoader();
    this.programservice.getProgram().subscribe((res: ServerResponse) => {
      this._spinner.hideLoader();
      if(res.success){
        this.programs = res.result.map((program) => ({
          id: program.id,
          name: program.name,
          plannedStartDate: program.plannedStartDate,
          vesselId: program.vesselId,
          vesselName: program.vessel.name,
          statusId: program.statusId,
          statusName: program.status.name,
        }));
      }
      else{

      }


    });
  }
  isDeleteIconVisible(e) {
    return true;
  }
  showAddProgramPopup() {
    this.showAddForm = true;
    this.showAddFormChange.emit(this.showAddForm);
  }
  onShowAddFormChange(value: boolean) {
    this.showAddForm = value;
    this.getProgramList();
  }

  showEditProgramPopup() {
    this.showEditForm = true;
  }
  getProgramDetails(ev: any) {
    const programId = ev.row.data.Id;
    ev.event.preventDefault();
    this.navigateTo(programId);
  }

  navigateTo(programId: any) {
    if (programId) {
      this._router.navigate([`/${programId}/sprints`]);
    } else {
      console.error('ProgramId is not available.');
    }
  }
  onRowRemoving(e: any): void {
    if (e.data.statusId === 6) {
      e.cancel = true;
      this.type='error'
      this.isVisible=true;
      this.message="You can not modify this program since the program is in progress"
      return;
    }
    const rowData = e.data; // Get the data of the row being removed
    this.programservice.deleteRow(rowData.id)
      .subscribe(response => {
        // Handle successful delete response
        console.log('Row deleted successfully', response);
      }, error => {
        // Handle error response
        console.error('Error deleting row', error);
        // Prevent row from being removed in case of an error
        e.cancel = true;
      });
  }
  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      if (e.data.statusId === 5) {
        e.rowElement.style.backgroundColor = '#d4d4d4';  // Light green for active
      } else if (e.data.statusId === 6) {
        e.rowElement.style.backgroundColor = '#fff3cd';  // Light red for inactive
      } else if (e.data.statusId === 7) {
        e.rowElement.style.backgroundColor = '#d4edda';  // Light yellow for pending
      }
    }
  }
  onEditingStart(e: any) {
    if (e.data.statusId === 6) {
      e.cancel = true;
      this.type='error';
      this.isVisible=true;
      this.message="You can not modify this program since the program is in progress"
    }
  }



}
