import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCache } from '../shared/services/RequestCache';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //var cacheCondition = req.method == 'GET' && req.url.indexOf('personalinfo') > 0;
    var cacheCondition = false;
    const cachedResponse = cacheCondition ? this.cache.get(req) : null;
    return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        var cacheCondition = req.method == 'GET';
        if (cacheCondition && event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
