// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px; /* Adjust as needed */
}

.card {
  width: 100%; /* Full width */
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden; /* Ensures image doesn't overflow */
}

.image {
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-size: cover;
}

.text {
  flex: 1; /* Takes up remaining space */
  padding: 10px;
  border-left: 1px solid #000000;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/report/report.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY,EAAE,qBAAqB;AACrC;;AAEA;EACE,WAAW,EAAE,eAAe;EAC5B,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB,EAAE,mCAAmC;AACvD;;AAEA;EACE,YAAY,EAAE,qBAAqB;EACnC,aAAa,EAAE,qBAAqB;EACpC,sBAAsB;AACxB;;AAEA;EACE,OAAO,EAAE,6BAA6B;EACtC,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,YAAY;AACd","sourcesContent":[".card-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 300px; /* Adjust as needed */\n}\n\n.card {\n  width: 100%; /* Full width */\n  display: flex;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  overflow: hidden; /* Ensures image doesn't overflow */\n}\n\n.image {\n  width: 100px; /* Adjust as needed */\n  height: 100px; /* Adjust as needed */\n  background-size: cover;\n}\n\n.text {\n  flex: 1; /* Takes up remaining space */\n  padding: 10px;\n  border-left: 1px solid #000000;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
