// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  .body-content {
    padding-top: 50px;
  }
}
:host {
  background-color: #f2f2f2;
  display: flex;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,6FAAA;EACA;IACE,iBAAA;EACF;AACF;AACA;EAEE,yBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AAAF","sourcesContent":["@media (max-width: 767px) {\n  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */\n  .body-content {\n    padding-top: 50px;\n  }\n}\n:host {\n  @import \"../themes/generated/variables.base.scss\";\n  background-color: darken($base-bg, 5.00);\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
