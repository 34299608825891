import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { VesselService } from "../../../shared/services/vessels.service";
import { Vessel } from "src/app/shared/Models/Vessel";
import { FormGroup } from "@angular/forms";
import { DxDropDownBoxTypes } from "devextreme-angular/ui/drop-down-box";
import { VesselType } from "src/app/shared/Models/VesselType";
@Component({
  selector: "app-vessel-add",
  templateUrl: "./add.component.html",
  styleUrl: "./add.component.css",
})
export class AddComponent implements OnInit{
  TypeOptions: string[];
  vesselName:string=null;
  imoNumber:number=null;
  vesselTypes:VesselType[]=[];
  @Input() isShowAddForm: boolean;
  @Output() close = new EventEmitter();
  vessel: Vessel = new Vessel();
  constructor(
    public vesselservice: VesselService,
    private toastr: ToastrService
  ) {

  }
  ngOnInit(): void {
    this.getTypeOptions();
  }
  getTypeOptions() {
    this.vesselservice.getVesselType().subscribe((vessels: any[]) => {
      this.vesselTypes=vessels;
      this.TypeOptions = vessels.map((vessel) => vessel.name);
    });
  }
  closeAddVesselPopup() {
    this.isShowAddForm = false;
    this.close.emit('1');
  }
  selectedType: string | null = null;

  onTypeSelectionChanged( e: DxDropDownBoxTypes.OptionChangedEvent) {

    this.selectedType = e.value;
    this.vessel.typeName = this.selectedType;
  }
  // DataSaved() {
  //   if (this.vessel.Name && this.vessel.imoNumber && this.vessel.TypeName) {
  //     this.vesselservice.saveData(this.vessel);
  //   } else {
  //     this.toastr.error("Please fill in all the required fields");
  //   }
  // }
  VesselCreate = new FormGroup({});
  DataSaved() {
    this.vessel.name=this.vesselName;
    this.vessel.imoNumber=this.imoNumber;
    debugger
    this.vessel.vesselTypeId= this.vesselTypes.find(x=>x.name==this.selectedType)?.id;
    let reqObj = {};
    this.vesselservice.saveData(this.vessel).subscribe(
      (response) => {
        this.close.emit('2');
        this.isShowAddForm=false;
      },
      (error) => {
        console.error("Error status:", error.status);
        console.error("Error message:", error.message);
      }
    );
  }
}
