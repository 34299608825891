// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
}
.logos-container svg {
  display: inline-block;
}

.dx-field-label {
  font-size: 14px;
}

.dx-state-disabled.dx-widget {
  opacity: 1 !important;
}

.shadow-page {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.breadcrum .dx-toolbar {
  padding: 15px !important;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 13px;
}

.angular-logo {
  width: 220px;
  height: 62px;
}

.plus {
  margin: 15px 10px;
  width: 22px;
  height: 22px;
}

:host-context(.screen-x-small) .logos-container svg {
  width: 100%;
  display: block;
}
:host-context(.screen-x-small) .logos-container svg.plus {
  margin: 0;
}

.company-contact-lable {
  width: 80px;
  display: inline-block;
}

.upload-img {
  width: 50px;
  height: 38px;
}

input[type=file] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  margin: 7px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AAAE;EACE,qBAAA;AAEJ;;AACA;EACE,eAAA;AAEF;;AACA;EACE,qBAAA;AAEF;;AACA;EACE,qDAAA;AAEF;;AACA;EACE,wBAAA;EACA,qDAAA;AAEF;;AACA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AACA;EACE,YAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AAEF;;AAEE;EACE,WAAA;EACA,cAAA;AACJ;AAAI;EACE,SAAA;AAEN;;AAGA;EACE,WAAA;EACA,qBAAA;AAAF;;AAEA;EACE,WAAA;EACE,YAAA;AACJ;;AAEA;EACE,aAAA;AACF;;AACA;EACE,sBAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;AAEF","sourcesContent":[".logos-container {\n  margin: 20px 0 40px 0;\n  text-align: center;\n  svg {\n    display: inline-block;\n  }\n}\n.dx-field-label {\n  font-size: 14px;\n}\n\n.dx-state-disabled.dx-widget{\n  opacity: 1 !important;\n}\n\n.shadow-page{\n  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.2) !important;\n}\n\n.breadcrum .dx-toolbar{\n  padding: 15px !important;\n  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.2) !important;\n} \n\n.devextreme-logo {\n  width: 200px;\n  height: 34px;\n  margin-bottom: 13px;\n}\n\n.angular-logo {\n  width: 220px;\n  height: 62px;\n}\n\n.plus {\n  margin: 15px 10px;\n  width: 22px;\n  height: 22px;\n}\n\n:host-context(.screen-x-small) .logos-container {\n  svg {\n    width: 100%;\n    display: block;\n    &.plus {\n      margin: 0;\n    }\n  }\n}\n\n.company-contact-lable{\n  width: 80px;\n  display: inline-block;\n}\n.upload-img{\n  width: 50px;\n    height: 38px;\n}\n\ninput[type=\"file\"] {\n  display: none;\n}\n.custom-file-upload {\n  border: 1px solid #ccc;\n  display: inline-block;\n  margin: 7px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
