import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { User } from "../Models/User";

@Injectable({
  providedIn: 'root',
})
export class CrewService{
  constructor(private http: HttpClient){

  }
  private token: string | null = null;
  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  private toastr: ToastrService;

  getCrewChecklistQuestion(sprintQuestionId:string) {
    return this.http.post<any[]>(this.apiURL + "Crew/GetCrewAssingedChecklistQuestion",sprintQuestionId ,this.options);
  }
  getQuestionById(QuestionId:string) {
    return this.http.post<any>(this.apiURL + "Crew/GetQuestionsById",QuestionId ,this.options);
  }
  getCrewQuestion() {
    return this.http.get<any>(this.apiURL + "Crew/GetQuestions", this.options);
  }
  saveChecklistAnswer(answer:any) {
    return this.http.post<any[]>(this.apiURL + "Crew/SaveCrewAnswer",answer ,this.options);
  }
  //AddCrew
  addCrew(crew:User) {
    return this.http.post<any[]>(this.apiURL + "Crew/AddCrew",crew ,this.options);
  }
}
