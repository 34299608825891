import { Injectable } from "@angular/core";
import { VesselCrews } from "src/app/shared/Models/VesselCrew";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ApproveAnswer } from "../Models/ApproveAnswer";
import { ServerResponse } from "../Models/ServerResponse";



@Injectable()
export class CaptainService {
  constructor(private http: HttpClient){

  }
  private token: string | null = null;
  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  getVesselQuestion() {
    return this.http.get<any>(this.apiURL + "Program/GetVesselIdQuestions", this.options);
  }
  crewChecklistQuestion(sprintQuestionId:number) {
    return this.http.post<any[]>(this.apiURL + "Program/GetCrewChecklistQuestions",sprintQuestionId ,this.options);
  }
  approveCrewAnswer(sprintAnswerId:number) {
    return this.http.post<any[]>(this.apiURL + "Program/approveCrewAnswer",sprintAnswerId ,this.options);
  }
  reAssignCrewAnswer(reassign:ApproveAnswer) {
    return this.http.post<ServerResponse>(this.apiURL + "Program/reAssignCrewAnswer",reassign ,this.options);
  }


}
