// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

.footer-style {
  background-color: white;
  margin-left: 0;
  padding-left: 40px;
  margin-right: 0;
  margin-top: 0px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.main-content {
  background-color: #f2f2f2;
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,OAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;AACJ;;AAEA;EACE,yBAAA;EACA,oBAAA;AACF","sourcesContent":[":host {\n  flex-direction: column;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n.layout-header {\n  z-index: 1501;\n}\n\n.layout-body {\n  flex: 1;\n  min-height: 0;\n}\n\n.content {\n  flex-grow: 1;\n}\n\n.footer-style{\n  background-color: white;\n    margin-left: 0;\n    padding-left: 40px;\n    margin-right: 0;\n    margin-top: 0px;\n    padding-right: 40px;\n    bottom: 0;\n    width: 100%;\n    left: 0;\n    right: 0;\n}\n\n.main-content{\n  background-color:#f2f2f2;\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
