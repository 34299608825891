import { Injectable } from '@angular/core';
import { VesselCrews } from 'src/app/shared/Models/VesselCrew';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AssignCrewDTO } from '../Models/AssignCrewDTO';
import { environment } from 'src/environments/environment';



@Injectable()
export class VesselCrewService {
  constructor(private http: HttpClient) {

  }
  private token: string | null = null;
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  private toastr: ToastrService;
  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }
  getVesselCrew() {
    return this.http.get<any[]>(this.apiURL + 'Vessel/GetCrew', this.options);
  }
  saveData(vesselcrew: VesselCrews) {
    this.toastr.success('Data saved successfully!');
  }
  assignQuestion(crewQuestion: AssignCrewDTO) {
    return this.http.post<any>(this.apiURL + 'Vessel/assignCrewQuestion', crewQuestion, this.options);
  }
  deleteData(id: any) {
    return this.http.post<any[]>(
      this.apiURL + 'Vessel/DeleteVesselCrew',
      id,
      this.options
    );
  }

}
