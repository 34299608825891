import { Component, Input } from "@angular/core";
import { SireCalenderService } from "../sire-calendar.service";
import { VesselService } from "../../../shared/services/vessels.service";
import { Events } from "src/app/shared/Models/Events";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrl: "./add-event.component.css",
})
export class AddEventComponent {
  VesselOptions: string[];
  @Input() isShowAddForm: boolean;
  event: Events = new Events();
  now: Date = new Date();

  constructor(
    private vesselservice: VesselService,
    private calenderservice: SireCalenderService,
    private toastr: ToastrService
  ) {
    // this.VesselOptions = this.vesselservice
    //   .getVessels()
    //   .map((vessel) => vessel.Name);
  }
  selectedType: string | null = null;

  onTypeSelectionChanged(selectedValue: any) {
    this.selectedType = selectedValue.value;
  }
  DataSaved() {
    if (this.event.Name && this.event.StartDate && this.event.VesselName) {
      this.calenderservice.saveData();
    } else {
      this.toastr.error("Please fill in all the required fields");
    }
  }
}
