import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.css']
})
export class AccountInformationComponent implements OnInit {
  toolbaritems: any[];
  constructor(public _router: Router) {
    this.toolbar();
  }

  ngOnInit() {
  }

  toolbar() {
    this.toolbaritems = [{
      location: 'before',
      template: () => {
        return '<div class=\'toolbar-cursor\'><b>Documents Management / </b></div>';
      },
      onClick: () => {
        this._router.navigate(['/documents-management']);
      }
    }, {
      location: 'before',
      template: () => {
        return '<div class=\'toolbar-label\'><b>Human Resources / </b></div>';
      }
    }];
  }

  companies: Company[] = [
    {
      ID: 1,
      title: "Prestaciones",
      CompanyName: "Prestaciones",
      template: "template1"
    },
    { ID: 2, title: "Insumos", CompanyName: "Insumos", template: "template2" }
  ];
}

export class Company {
  ID: number;
  CompanyName: string;
  title: string;
  template: string;
}
