import { Component } from "@angular/core";
import { ReportService } from "../report.service";
import { CrewPerformanceReport } from "src/app/shared/Models/CrewPerformanceReport";
import { Router } from "@angular/router";

@Component({
  selector: "app-crew-performance",
  templateUrl: "./crew-performance.component.html",
  styleUrl: "./crew-performance.component.css",
})
export class CrewPerformanceComponent {
  CrewPerformanceOptions: CrewPerformanceReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.CrewPerformanceOptions = reportservice.getCrewPerformanceReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
