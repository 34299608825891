// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  color: rgba(0, 0, 0, 0.609);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/footer/footer.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,2BAAA;EACA,wCAAA;EACA,iBAAA;EACA,oBAAA;AADF","sourcesContent":["@import \"../../../../themes/generated/variables.base.scss\";\n\n:host {\n  display: block;\n  color: rgba($base-text-color, alpha($base-text-color) * 0.7);\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  padding-top: 20px;\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
