import { Component } from "@angular/core";
import { UserActivityLogReport } from "src/app/shared/Models/UserActivityLogReport";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-activity-log",
  templateUrl: "./user-activity-log.component.html",
  styleUrl: "./user-activity-log.component.css",
})
export class UserActivityLogComponent {
  UserActivityLogOptions: UserActivityLogReport[];
  constructor(public reportservice: ReportService, public _route: Router) {
    this.UserActivityLogOptions = reportservice.getUserActivityLogReports();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
}
