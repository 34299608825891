import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { Tabs } from "../../shared/Models/Tab";
import notify from "devextreme/ui/notify";
import { AppInfoService } from "src/app/shared/services";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  urlProfile = "api/personalinfo";
  urlBsinfo = "api/BusinessInfo";
  urlFile = "api/file";
  toolbaritems: any[];
  bsinfoData: Bsinfo;
  bsinfoDataBackup: any;
  personalInfoData: Psinfo;
  personalInfoDataBackup: Psinfo;
  icinfoData: any;
  personalUsername: string;
  personalEmail: string;
  personalFullname: string;
  personalFirstname: string;
  personalLastname: string;
  isRTO: any = false;
  personalRole: string;
  personaldataupdate: any;
  businessdataupdate: any;
  updatePersonalData: any;
  businessinfoupdate: boolean = false;
  editBusinessInfo: boolean;
  editPersonalInfo: boolean;
  popupVisible: boolean;
  userImage: any;
  userImageUrl: any;
  businessImage: any;
  businessImageUrl: any;
  defaultUserImage: string = "assets/no-user-img.jpg";
  defaultBusinessLogo: string = "assets/no-company-img.jpg";
  reminderForLicense: boolean;
  reminderForInsurance: boolean;
  licenseExpiryDate: string;
  insuranceExpiryDate: string;
  newPasswordDetails = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  buttonOptions: any = {
    text: "Update",
    type: "default",
    useSubmitBehavior: true,
  };
  programs: any[];
  vessels: number;
  crewData: any[];
  programStatus: any[];
  totalCrewCount: number;
  pieChartData: { rank: string, count: number }[];


  constructor(
    private http: HttpClient,
    public _router: Router,
    public appInfo: AppInfoService,

  ) {
    this.editPersonalInfo = false;
    this.editBusinessInfo = false;
    this.userImageUrl = this.defaultUserImage;
    this.businessImageUrl = this.defaultBusinessLogo;
    this.icinfoData = {
      name: null,
      license: null,
      licenseExpiresOn: null,
      phoneNo: null,
      email: null,
      uidNumber: null,
      insurerName: null,
      commencementDate: null,
      expirationDate: null,
    };
    this.programs = [
      { name: 'Program A', created: 10 },
      { name: 'Program B', created: 15 },
      { name: 'Program C', created: 5 }
    ];

    this.programStatus = [
      { status: 'Active', count: 10 },
      { status: 'Completed', count: 7 },
      { status: 'Pending', count: 3 }
    ];

    this.vessels = 20;
    this.crewData = [
      { rank: 'Captain', count: 10 },
      { rank: 'First Officer', count: 15 },
      { rank: 'Engineer', count: 5 },
      // Add more ranks as needed
    ];    // Example count of crew
    this.totalCrewCount = this.crewData.reduce((total, crew) => total + crew.count, 0);
    this.pieChartData = [...this.crewData, { rank: 'Total Crew', count: this.totalCrewCount }];

  }

  ngOnInit() {
    this.toolbar();
    this.popupVisible = false;
    this.reminderForLicense = false;
    this.reminderForInsurance = false;
  }

  passwordComparison = () => {
    return this.newPasswordDetails.newPassword;
  };

  updatePassword() {
    if (
      this.newPasswordDetails.oldPassword != null &&
      this.newPasswordDetails.newPassword != null
    ) {
      this.personalInfoData.oldPassword = this.newPasswordDetails.oldPassword;
      this.personalInfoData.password = this.newPasswordDetails.newPassword;
      this.http
        .put("api/login", this.personalInfoData)
        .subscribe((response) => {
          this.popupVisible = false;
          notify(response, "warning", 600);
        }),
        catchError((error) => {
          debugger;
          notify(error, "error", 600);
          return throwError("Something went wrong");
        });
    } else {
      notify("Enter Old and New Password", "warning", 600);
    }
  }

  changePassword() {
    this.newPasswordDetails.oldPassword = null;
    this.newPasswordDetails.newPassword = null;
    this.newPasswordDetails.confirmPassword = null;
    this.popupVisible = true;
  }

  reminder(expiryDate) {
    var dateToday = new Date();
    var dateDiff =
      expiryDate.getMonth() -
      dateToday.getMonth() +
      12 * (expiryDate.getFullYear() - dateToday.getFullYear());
    if (dateDiff <= 1) {
      return true;
    } else {
      return false;
    }
  }

  toolbar() {
    this.toolbaritems = [
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-cursor'><b>Home / </b></div>";
        },
        onClick: () => {
          this._router.navigate(["/home"]);
        },
      },
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-label'><b>Account Information  </b></div>";
        },
      },
    ];
  }

  cancelPersonalDetail() {
    this.editPersonalInfo = false;
    this.personalInfoData = JSON.parse(
      JSON.stringify(this.personalInfoDataBackup)
    );
  }
  editPersonalDetailClicked() {
    //notify('Something went wrong', 'error', 600);
    this.editPersonalInfo = true;
    this.personalInfoDataBackup = JSON.parse(
      JSON.stringify(this.personalInfoData)
    );
  }
  titleClick() {
    this.editPersonalInfo = false;
    this.businessinfoupdate = false;
  }

  uploadUserImage(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      notify("Only images are supported", "success", 600);
      //this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.userImageUrl = reader.result;
    };
    this.userImage = files[0];
  }

  updatePersonalInfo(args) {
    args.preventDefault();
    if (this.userImage) {
      this.getBase64(this.userImage).then((v) => {
        this.personalInfoData.imageData = v;
        this.http
          .put(this.urlProfile, this.personalInfoData)
          .subscribe((response) => {
            this.personaldataupdate = response;
            this.editPersonalInfo = false;
            notify("Personal Info updated successfully", "success", 600);
          }),
          catchError((error) => {
            return throwError("Something went wrong");
          });
      });
    } else {
      this.http
        .put(this.urlProfile, this.personalInfoData)
        .subscribe((response) => {
          this.personaldataupdate = response;
          this.editPersonalInfo = false;
          notify("Personal Info updated successfully", "success", 600);
        }),
        catchError((error) => {
          return throwError("Something went wrong");
        });
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^.*,/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      } else {
        resolve(null);
      }
    });
  }
  uploadBusinessImage(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      notify("Only images are supported", "success", 600);
      //this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.businessImageUrl = reader.result;
    };
    this.businessImage = files[0];
  }

  editBusinessInfoClicked() {
    this.businessinfoupdate = true;
    this.editBusinessInfo = true;
    this.bsinfoDataBackup = JSON.parse(JSON.stringify(this.bsinfoData));
  }

  cancelBusinessInfoEdit() {
    this.bsinfoData = JSON.parse(JSON.stringify(this.bsinfoDataBackup));
    this.businessinfoupdate = false;
    this.editBusinessInfo = false;
  }

  updateBusinessInfo() {
    this.businessinfoupdate = true;
    this.getBase64(this.businessImage).then((v) => {
      this.bsinfoData.logoData = v;
      this.http.put(this.urlBsinfo, this.bsinfoData).subscribe((response) => {
        this.businessdataupdate = response;
        this.businessinfoupdate = false;
        this.editBusinessInfo = false;
      }),
        catchError((error) => {
          return throwError("Something went wrong");
        });
    });
  }

  tabs: Tabs[] = [
    {
      ID: 1,
      title: "Personal Info",
      template: "template1",
    },
    {
      ID: 2,
      title: "Business Info",
      template: "template2",
    },
  ];
}

export class Psinfo {
  additionalUsers: any;
  adminClientCode: any;
  canUseDm: any;
  canUseGa: any;
  canUsePm: any;
  canUseSales: any;
  canUseNc: any;
  canUseLs: any;
  canUseLearn: any;
  canUseTa: any;
  clientCode: any;
  currentUser: any;
  dateExpiryAcc: any;
  dateExpiryDoc: any;
  dateRegistered: any;
  email: any;
  emailConfirmed: any;
  firstName: any;
  isActive: any;
  isAdmin: any;
  lastName: any;
  noOfUsers: any;
  password: any;
  oldPassword: any;
  sno: any;
  userName: any;
  image: any;
  imageData: any;
  xmlData: any;
  xmlExplorer: any;
  licenseExpiresOn: any;
  expirationDate: any;
  uidNumber: any;
  insurerName: any;
  commencementDate: any;
  role: any;
}

export class Bsinfo {
  abn: string;
  acn: string;
  address: string;
  address2: string;
  clientCode: string;
  companyName: string;
  email: string;
  fax: string;
  logo: any;
  logoData: any;
  mobileNo: string;
  phoneNo: string;
  website: string;
  uidNumber: any;
  insurerName: any;
  commencementDate: any;
  expirationDate: any;
}

export class Tab {
  id: number;
  text?: string;
  icon?: string;
}
