import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApproveAnswer } from 'src/app/shared/Models/ApproveAnswer';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { CaptainService } from 'src/app/shared/services/captain.service';
import { LoggerService } from 'src/app/shared/services/loger.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-manage-question',
  templateUrl: './manage-question.component.html',
  styleUrl: './manage-question.component.css'
})
export class ManageQuestionComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _captainService:CaptainService,
    private _spinnerService:SpinnerService,
    private _loggerService:LoggerService

  )
  {

  }
  overallProgress: number = 0;
  id:number;
  checklistData :any[]=[];
  showReAssignPopup: boolean = false;
  masterNote:string=null;
  selectedId:number=null;
  reAssignDto:ApproveAnswer = new ApproveAnswer();

  onEdit(event: any) {
    // Handle edit action
    console.log('Edit:', event);
  }

  onDelete(event: any) {
    // Handle delete action
    console.log('Delete:', event);
  }
  ngOnInit() {
    this._route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.getCrewAssignedChecklistQuestion(this.id);
  }
  getCrewAssignedChecklistQuestion(sprintQuestionId:number){
    this._captainService.crewChecklistQuestion(sprintQuestionId).subscribe((res=>{
      this.checklistData= res.sort((a, b) => a.statusId - b.statusId);
      this.calculateOverallProgress();
    }))
  }
  confirmReAssign() {
    if(this.masterNote==null){
      this._loggerService.logError("Please Enter Master Note");
      return;
    }
    this.reAssignDto.id = this.selectedId;
    this.reAssignDto.masterNote = this.masterNote;
    this._captainService.reAssignCrewAnswer(this.reAssignDto).subscribe((res:ServerResponse)=>{
      if(res.success){
        this._loggerService.logSuccess("Reassigned Successfully");
        this.getCrewAssignedChecklistQuestion(this.id);
      }
    },(error)=>{

    })
    // Close the popup
    this.closeReAssignPopup();
  }

  closeReAssignPopup() {
    this.showReAssignPopup = false;
    this.selectedId=null;
    this.masterNote=null;
    this.reAssignDto = new ApproveAnswer();
  }
  openReAssignPopup(item: any) {
    this.showReAssignPopup = true;
    this.selectedId = item.id;
  }
  approveCrewAnswer(item :any){
    this._captainService.approveCrewAnswer(item.id).subscribe((res=>{
      this.getCrewAssignedChecklistQuestion(this.id);

    }))

  }

  calculateOverallProgress(): void {
    if (this.checklistData.length === 0) {
      this.overallProgress = 0;
      return;
    }
    const completedItems = this.checklistData.filter(item => item.statusId === 7).length;

    // Calculate progress as a percentage
    this.overallProgress = Math.round((completedItems / this.checklistData.length) * 100);
  }
  getOverallProgressClass(): string {
    if (this.overallProgress === 100) {
      return 'completed-progress';
    } else if (this.overallProgress >= 50) {
      return 'in-progress-progress';
    } else {
      return 'pending-progress';
    }
}
}
